import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../css/ComparisonResults.css';
import MainWrapper from '../wrappers/mainWrapper';
import Button from '@mui/material/Button';
import Multiselect from 'multiselect-react-dropdown';
import Modal from 'react-modal';
import axios from 'axios'
import jsPDF from 'jspdf'
import LevlrLogo from '../../img/LEVLR Logo_CMYK.png'
import Select from 'react-select';
import GO from '../../img/Go.png';
import Close from '../../img/pretty-close-2.png';
import Stop from '../../img/_Stop.png';
import Yield from '../../img/_Yeild.png';

const ComparisonResults = props => {

  let doc1ZoneState = []
  const [newZonesToCompare, setNewZonesToCompare] = useState([])
  var stringSimilarity = require("string-similarity");
  const [responded, setResponded] = useState(false)
  const [getSubzones, setGetSubzones] = useState([])
  const [data, dataSet] = useState({})
  const [filter, setFilter] = useState("percentage");
  const [percentValue, setPercentValue] = useState(43.0);
  const [currentSection, setCurrentSection] = useState("results");
  const { comparisonID } = useParams();
  const [markForExport, setMarkForExport] = useState(new Set)
  const [open, setOpen] = useState(false)
  const [currentNoteId, setCurrentNoteId] = useState("")
  const [loadingStatus, setLoadingStatus] = useState('loading-spinner')
  const pdfRef = useRef(null);
  const MAX = 85;
  const [noteLog, setNoteLog] = useState([])
  const [currentNote, setCurrentNote] = useState("")
  const [markConfirm, setMarkConfirm] = useState(new Set)
  const [markAllCheckboxes, setMarkAllCheckboxes] = useState(new Set)
  const [newViewArray, setNewViewArray] = useState([])
  const [pdfCopyContent, setPdfCopyContent] = useState(false);
  const [doc1Name, setDoc1Name] = useState("");
  const [doc2Name, setDoc2Name] = useState(""); 


  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [doc2Visibility, setDoc2Visibility] = React.useState({});

  const toggleDoc2Visibility = (key) => {
    setDoc2Visibility((prevState) => ({
      ...prevState,
      [key]: !prevState[key],  // Toggle visibility per item
    }));
  };

  const [toggled, setToggled] = useState(true)
  const maxLength = 60; // Set the maximum length for visible content

  const [selectedZone1, setSelectedZone1] = useState([]);
  const [selectedZone2, setSelectedZone2] = useState([]);
  const [filterTextZone1, setFilterTextZone1] = useState(''); // State for zone 1 filter text
  const [filterTextZone2, setFilterTextZone2] = useState(''); // State for zone 2 filter text
  const [filteredItems, setFilteredItems] = useState({ zone1: [], zone2: [] });
  const [confirmedForExport, setConfirmedForExport] = useState([]);
  //console.log("markForExport: " + JSON.stringify(Array.from(markForExport), null, 2));
  //console.log("confirmedForExport: " + confirmedForExport)
  //console.log("markConfirm: " + JSON.stringify(markConfirm,null,2))
  const openPreviewInNewTab = () => {
    const pdfCopy = document.getElementById('pdfCopy');

    if (pdfCopy) {  
      // Clone the pdfCopy content to avoid modifying the original
      const clonedContent = pdfCopy.cloneNode(true);

      // Remove all label elements within the cloned content
      const labels = clonedContent.querySelectorAll('label');
      labels.forEach((label) => label.remove());

      // Open a new tab and inject the modified content
      const newTab = window.open('', '_blank');
      newTab.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>Preview</title>
        <style>
          /* Add any styling you want for the preview here */
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
          }
        </style>
      </head>
      <body>
        ${clonedContent.innerHTML}
      </body>
      </html>
    `);
      newTab.document.close(); // Ensure the document is fully loaded
    } else {
      alert('No content to preview');
    }
  };

  // Generate PDF based on pdfCopy contents
  const generatePDF = () => {
    const pdfCopy = document.getElementById('pdfCopy');

    if (pdfCopy && pdfCopy.children.length > 0) {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a1',
        hotfixes: ['px_scaling'],
      });

      const fileName = prompt('Please Enter A File Name.');

      // Adding Claim Credentials at the top
      if (data["claimData"]) {
        const { adjuster, insured, insured_address, claim_number } = data["claimData"];

        doc.setFontSize(12);
        doc.setTextColor('#333333'); // Set text color if desired

        // Adding each credential at a specific y-position for spacing
        doc.text(`Adjuster: ${adjuster}`, 20, 30);
        doc.text(`Insured: ${insured}`, 20, 50);
        doc.text(`Insured Address: ${insured_address}`, 20, 70);
        doc.text(`Claim Number: ${claim_number}`, 20, 90);
      }

      // Render content from pdfCopy
      doc.html(pdfCopy, {
        callback: function (doc) {
          doc.addImage(LevlrLogo, 'PNG', 2000, 20, 203, 70);
          doc.save(`${fileName}.pdf`);
        },
      });
    } else {
      alert('No selections made for export.');
    }
  };

//styles
const tableStyle = { 
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 12px 24px 12px',
  overflow: 'auto',
  border: '1px dotted black'
}
const filterTableStyle = {
  position: 'relative',
  height: 'auto',
  width: '98%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 0px 24px 0px',
  overflow: 'auto',
  border: '1px dotted black'
}
const modalStyle = {
  position: 'absolute',
  display: 'grid',
  overflow: 'hidden',
  backgroundColor: 'white',
  opacity: '95%',
  width: '100%',
  height: '100%',
  borderRadius: '15px',
  padding: '12px 12px',
  boxSizing: 'border-box'
}

const modalRestyle = {
  overlay: {
    width: '40%',
    height: '44%',
    top: '54%',
    left: '1%',
    overflow: 'hidden',
    borderRadius: '15px',
    border: '4px solid black',
    boxSizing: 'border-box',
    padding: '0 0',
  },

  content: {
    overflow: 'hidden',
    background: 'none',
    border: 'none',
    inset: '0px',
    padding: '0 0'
  },
}

const noteStyle = {
  border: '2px solid #526666',
  resize: 'none',
  fontSize: '20px',
  padding: '4px 4px',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box'
}

const notInAnyZone = {
  fontSize: '24px',
  textAlign: 'center',
  padding: '0px 0px',
  margin: '0px 0px'
}

const endOfDocTable = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  margin: '12px 24px 24px 0px',
  padding: '12px 8px',
  overflow: 'auto',
  border: '1px dotted black',
}

const pdfCopy = {
  display: 'flex-box',
  flexDirection: 'column',
  margin: '128px 12px 12px 12px',
  padding: '16px',
  fontSize: '28px',
  width: '100%',
  overflowY: 'auto',
  boxSizing: 'border-box'
}

const theadStyle = {
  fontSize: '20px'
}

const newHeaderStyle = {
  fontSize: '32px',
  paddingBottom: '12px'
}

const borderTop = {
  position: 'relative',
  gridTemplateRows: 'auto auto',
  height: 'auto',
  width: '100%',
  borderRadius: '4px',
  borderTop: '2px solid black',
  marginTop: '36px'
}

const getZonePairingsContainer = {
  display: 'block',
}

const noMatchTableStyle = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 24px 24px 0px',
  overflow: 'auto',
  border: '1px dotted black'
}

const lastRow = {
  display: 'flex',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto',
  width: '100%',
  alignItems: 'center',
  padding: '4px 4px'
}

const filterStyle = {
  height: '16px',
  padding: '6px'
}

const centeredHeader = {
  textAlign: 'center'
}

const blockStyle = {
  display: 'block'
}

const mapStyle = {
  position: 'relative',
  height: 'auto',
  display: 'grid',
  gridTemplateRows: 'auto auto',
  marginTop: '6px',
}

const verticalLabel = {
  padding: '16px 4px',
  margin: '4px 4px',
  textAlign: 'center',
  border: 'none',
  fontStyle: 'italic',
  opacity: '50%',
  textDecoration: 'underline'
}

const labelStyle = {
  margin: '12px 0px',
  color: '#00B3B5',
  textAlign: 'left'
}

const doc2ZoneStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#00B3B5', 
  textDecoration: 'underline',
  fontSize: '24px'
}

const addNoteButton = {
  backgroundColor: '#00B3B5',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const updateNoteButton = {
  backgroundColor: '#723E49',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const zoneStyle = {
  margin: '12px 0px',
  fontWeight: 'bold',
  fontSize: '1em',
  padding: '2px 12px',
  color: '#526666'
}

const thStyle = {
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#526666',
  border: 'none',
  textAlign: 'left',
  alignSelf: 'center'
}

const trStyle = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'space-between'
}

const exportButtons = {
  display: 'flex',
  position: 'fixed',
  margin: '0 auto',
  padding: '0 48px',
  boxSizing: 'border-box',
  justifyContent: 'flex-end',
  width: '85%',
  zIndex: '1000',
  top: '10%',
  left: '15%'
}

const trStyleFlexStart = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'flex-start'
}

const tbodyStyle = {
  overflow: 'auto',
  display: 'inline'
}

const tbodyNote = {
  overflow: 'auto',
  display: 'inline',
  marginTop: '24px',
  inlineSize: '80%',
  wordBreak: 'break-all',
  fontWeight: 'bold',
  fontSize: '18px',
}

const tdPropStyle = {
  display: 'block',
  margin: '0px 0px',
  color: '#00B3B5',
  textAlign: 'left',
}

const tdValueStyle = {
  color: '#526666',
}

const containsPropVal = {
  display: 'block',
  width: '200px',
  overflow: 'auto',
  padding: '0 4px',
  margin: '12px 0px 0px 0px'
}  

useEffect(() => {
  // Load and handle data if not already loaded
  getSubzonesData();
  getNotesData();
  getConfirmData();

  if (!responded) {
    getResultsData();
  } else if (responded && getSubzones.length > 0) {
    setNewZonesToCompare(getSubzones[0].stateObject);
    setLoadingStatus('');
  } else if (responded) {
    setNewZonesToCompare(doc1ZoneState.map((val, index) => ({
      Id: index,
      Doc1: val,
      SubZones: [],
    })));
    setLoadingStatus('');
  }

  renderZones();

  // Event listener for beforeunload (clean-up if noteLog changes)
  const handler = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  if (noteLog.length > 0) {
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }

  // Second part: Filter line items, handle PDF content, and confirm export
  if (data.results) {
    filterLineItems();
  }

  const pdfCopy = document.getElementById('pdfCopy');
  if (pdfCopy) {
    setPdfCopyContent(pdfCopy.childElementCount > 0);
  }

  // Directly update the export status only when necessary
  setConfirmedForExport(markForExport);

}, [
  newViewArray,
  responded,
  loadingStatus,
  noteLog,
  data,
  selectedZone1,
  selectedZone2,
  filterTextZone1,
  filterTextZone2,
  markForExport, // Avoid frequent updates here
]);

  const getResultsData = async () => {
    const comp = await fetch(`${process.env.REACT_APP_RESULTS_DATA}comp/${comparisonID}`, { timeout: 8000 }).then((response) => response.json({}))
    if (comp.hasOwnProperty("full_resultsID") || comp.hasOwnProperty("results_ID")) {
      if (comp["full_resultsID"] === "NaN") {
        const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
        dataSet({
          results: JSON.parse(split.data),
          full_results: JSON.parse(JSON.stringify(split.data)),
          claimData: comp.claimData,
          files: comp.files,
          headers: comp.headers,
          resultsID: comp.resultsID,
          full_resultsID: comp.full_resultsID,
          dateUploaded: comp.dateUploaded,
          statusMSG: comp.statusMSG,
          status: comp.status,
          doc1_name: comp.doc1_name,
          doc2_name: comp.doc2_name,
          headersOne: comp.headersOne,
          headersTwo: comp.headersTwo,
          no_match_a: comp.no_match_a,
          no_match_b: comp.no_match_b
        })
        setDoc1Name(comp?.claimData?.doc1_name)
        setDoc2Name(comp?.claimData?.doc2_name)

        setResponded(true)
      }
      else if (comp["full_resultsID"] != "NaN") {
        const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
        const fullSplit = await fetch(`${process.env.REACT_APP_SPLIT_FULL_RESULTS}/${comp.full_resultsID}`).then((response) => response.json({}))
        dataSet({
          results: JSON.parse(split.data),
          full_results: JSON.parse(JSON.stringify(fullSplit.data)),
          claimData: comp.claimData,
          files: comp.files,
          headers: comp.headers,
          resultsID: comp.resultsID,
          full_resultsID: comp.full_resultsID,
          dateUploaded: comp.dateUploaded,
          statusMSG: comp.statusMSG,
          status: comp.status,
          doc1_name: comp.doc1_name,
          doc2_name: comp.doc2_name,
          headersOne: comp.headersOne,
          headersTwo: comp.headersTwo,
          no_match_a: comp.no_match_a,
          no_match_b: comp.no_match_b
        })
        setResponded(true)
        setDoc1Name(comp?.claimData?.doc1_name)
        setDoc2Name(comp?.claimData?.doc2_name)
      }
    }
    else {
      dataSet(comp)
      setResponded(true)
    }
  }

  //Function to collect subzone record
  const getSubzonesData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_SUBZONES}${comparisonID}`, { timeout: 8000 }
    ).then((response) => response.json({}));
    setGetSubzones(response)
  };

  //Function to collect subzone record
  const getNotesData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_NOTES}${comparisonID}`, { timeout: 8000 }
    ).then((response) => response.json({}));
    if (Object.keys(response).length > 0) {
      setNoteLog(response[0]["stateObject"])
    }
  };

  const getConfirmData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GET_CONFIRM}${comparisonID}`, { timeout: 8000 }
    ).then((response) => response.json({}));
    if (Object.keys(response).length > 0) {
      setMarkConfirm(response[0]["stateObject"])
    }
  };

  //Function to put subzones into database
  //id param is equal to comparisonID
  function handleSaveZones(id) {
    axios.put(`${process.env.REACT_APP_SAVE_ZONES}${id}`, newZonesToCompare)
      .then(response => {
        alert("Zones Saved To Database!");
        //console.log("Zones Posted.");
      })
      .catch(error => {
        alert("Saving was unsuccessful. Please try again.");
        console.error('There was an error!', error);
      });
  }

  function handleSaveNotes(id) {
    axios.put(`${process.env.REACT_APP_SAVE_NOTES}${id}`, noteLog)
      .then(response => {
        alert("Notes Saved To Database!");
        //console.log("Notes Posted.");
      })
      .catch(error => {
        alert("Saving was unsuccessful. Please try again.");
        console.error('There was an error!', error);
      });
  }

  function handleSaveConfirm(id) {
    axios.put(`${process.env.REACT_APP_SAVE_CONFIRM}${id}`, markConfirm)
      .then(response => {
        alert("No-Matches Saved To Database!");
        //console.log("No-Matches Posted.");
      })
      .catch(error => {
        alert("Saving was unsuccessful. Please try again.");
        //console.error('There was an error!', error);
      });
  }

  function saveChanges(id) {
    handleSaveNotes(id)
    handleSaveZones(id)
    handleSaveConfirm(id)
  }

  const openNote = (e, id) => {
    setOpen(true)
    setCurrentNoteId(id)
  }

  const updateNote = (e, id) => {
    setOpen(true)
    setCurrentNoteId(id)
  }

  function findPlaceholderText(id) {
    if (noteLog.find((element) => {
      return (element.id === id);
    })) {
      return (
        noteLog.find((element) => {
          return (element.id === id);
        })
      ).note
    }
    else return

  }

  function renderAddOrUpdate(currentNoteId) {
    if (findPlaceholderText(currentNoteId) === undefined) {
      return false
    }
    else {
      if (findPlaceholderText(currentNoteId).length > 0) {
        return true
      }
    }
  }

  function renderNote() {
    return (
      <Modal
        style={modalRestyle}
        isOpen={open}
        onRequestClose={() => handleNoteClose()}
        preventScroll={false}
        ariaHideApp={false}
      >
        <div style={modalStyle}>
          <h3 style={{ color: '#526666' }}>{renderAddOrUpdate(currentNoteId) ? "Please Update Your Note." : "Please Add A Note."}</h3>
          <textarea
            style={noteStyle}
            id={currentNoteId}
            name={currentNoteId}
            defaultValue={findPlaceholderText(currentNoteId)}
            rows="8"
            cols="50"
            onBlur={handleNoteChange}
          >
          </textarea>

          <Button
            size="small"
            sx={{
              ':hover': {
                backgroundColor: '#999999'
              },
              height: '50px',
              margin: '16px 0px 0px 0px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#00B3B5'
            }}
            onClick={handleNoteSave}
          >
            {renderAddOrUpdate(currentNoteId) ? "UPDATE NOTE" : "ADD NOTE"}
          </Button>

          <Button
            size="small"
            sx={{
              ':hover': {
                backgroundColor: '#999999'
              },
              margin: '0px 0px 8px 0px',
              fontWeight: 'bold',
              height: '50px',
              color: 'white',
              backgroundColor: '#723E49'
            }}
            onClick={handleNoteClose}
          >
            Exit
          </Button>
        </div>
      </Modal>
    )

  }

  function handleNoteClose() {
    setOpen(false)
  }

  const handleNoteChange = (e) => {
    setCurrentNote(e.target.value)
  }

  const handleNoteSave = (e) => {
    let objIndex = noteLog.find(obj => obj.id === currentNoteId)
    let newNote = {
      id: currentNoteId,
      note: currentNote
    }
    if (objIndex === undefined) {
      setNoteLog([...noteLog, newNote])
      setOpen(false)
    }
    else {
      objIndex.note = currentNote
      setOpen(false)
    }

  }

  function checkDisabledNote(id) {
    if ((noteLog.find(note => note['id'] === id)) === undefined) {
      return false
    }
    else {
      return true
    }
  }

  function renderClaimCredentials() {
    if (data["claimData"] != null || data["claimData"] != undefined) {
      return (
        <div style={newHeaderStyle} id={'newHeader'}>
          <p>Adjuster : {data["claimData"]["adjuster"]}</p>
          <p>Insured : {data["claimData"]["insured"]}</p>
          <p>Insured Address : {data["claimData"]["insured_address"]}</p>
          <p>Claim Number : {data["claimData"]["claim_number"]}</p>
        </div>
      )
    }
    else {
      return
    }
  }

  function renderAddNote(id) {
    if (!checkDisabledNote(id)) {
      return (
        <Button
          id={id}
          style={addNoteButton}
          onClick={(e) => openNote(e, id)}
        >
          Add Note
        </Button>
      )
    }
    else if (checkDisabledNote(id)) {
      return (
        <Button
          id={id}
          style={updateNoteButton}
          onClick={(e) => updateNote(e, id)}
        >
          Update Note
        </Button>
      )
    }
  }

  function renderProperNote(id) {
    return (
      noteLog.map((key, val) => {
        if (key.id === id) {
          return (
            <tr style={tbodyNote}>
              Note: {key.note}
            </tr>
          )
        }
      }
      )
    )
  }

  function noMatchesEndOfResultsA(lineItemZero) {
    if (data["no_match_a"] != null && data["no_match_b"] != null) {
      let a = JSON.parse(data["no_match_a"])
      let b = JSON.parse(data["no_match_b"])

      if (Object.keys(a).length > 0) {
        return Object.entries(a).map(([keys, vals]) => {
          const showDoc2 = doc2Visibility[keys] || false;
          const totalEntry = Object.entries(vals).find(
            ([k]) => k.toLowerCase() === "total"
          );

        return (
          <div
          className="redZone"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            borderBottom: "2px solid rgb(0, 179, 181)",
            padding: "0px 0px 10px 0px",
            margin: "5px 0px 10px 0px",
            backgroundColor: "#ffffff",
          }}
        >
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "40px",
              left: "0%",
              overflow: "hidden",
              zIndex: "9999",
              textAlign: "center",
              fontWeight: "bold",
              color: "rgb(0, 179, 181)",
              pointerEvents: "none",
              fontSize: "20px",
            }}
          >
            <img src={Stop} />
          </a>

          <table style={endOfDocTable} id={"zzz" + keys}>
            <thead style={theadStyle}>
              <tr>
                <th>
                  NOT FOUND IN ANY ZONE
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#526666",
                      padding: "2px 0px",
                      display: "inline",
                      margin: "2px",
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleDoc2Visibility(keys)}
                  >
                    Expand
                    <span
                      style={{
                        marginLeft: "8px",
                        display: "inline-block",
                        width: "0",
                        height: "0",
                        borderTop: "4px solid transparent",
                        borderBottom: "4px solid transparent",
                        borderLeft: "8px solid #526666",
                        transform: doc2Visibility[keys]
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transformOrigin: "4px 50%",
                        transition: "transform 0.2s ease",
                      }}
                    ></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody id={"string"} key={keys} style={tbodyStyle}>
              <tr style={trStyle}>
                {Object.entries(vals)
                  .slice(0, 1)
                  .map(([key, val]) => {
                    const totalEntry = Object.entries(vals).find(
                      ([k]) => k.toLowerCase() === "total"
                    );

                    return (
                      <>
                        <th
                          style={{
                            ...thStyle,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "90%",
                          }}
                          key={key}
                        >
                          <span title={`${key}: ${val}`}>
                            {doc2Visibility[keys]
                              ? `${key}: ${val}`
                              : `${
                                  key.length > 20
                                    ? key.substring(0, 20) + "..."
                                    : key
                                }` +
                                `: ${
                                  val.length > 30
                                    ? val.substring(0, 30) + "..."
                                    : val
                                }`}
                          </span>

                          {!doc2Visibility[keys] && totalEntry && (
                            <span
                              style={{
                                fontWeight: "bold",
                                textAlign: "right",
                              }}
                            >
                              <span style={{ color: "#00B3B5" }}>
                                Total:
                              </span>
                              <span style={tdValueStyle}>
                                {totalEntry[1]}
                              </span>
                            </span>
                          )}
                        </th>
                      </>
                    );
                  })}
              </tr>
              {doc2Visibility[keys] && (
                <>
                  <tr style={trStyle}>
                    <th style={verticalLabel}>
                      {findDocNumber(keys)}
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#00B3B5",
                          textDecoration: "underline",
                          zIndex: "1000",
                        }}
                        className={findDocNumber(keys) ? "doc1" : "doc2"}
                        onClick={async () => {
                          const isDoc1 = findDocNumber(keys);
                          const fieldName = isDoc1
                            ? "doc1_name"
                            : "doc2_name";
                          const currentName = isDoc1
                            ? data?.doc1_name
                            : data?.doc2_name;
                          const newName = window.prompt(
                            `Enter a new name for ${fieldName}:`,
                            currentName || "Unnamed"
                          );
                          if (newName !== null && newName.trim() !== "") {
                            try {
                              const claimId = comparisonID;
                              if (!claimId) {
                                console.error(
                                  "ERROR: Claim ID is missing!"
                                );
                                alert("Error: Claim ID is missing.");
                                return;
                              }
                              const requestBody = JSON.stringify({
                                [fieldName]: newName,
                              });
                              const response = await fetch(
                                `${process.env.REACT_APP_DOCNAMES}/${claimId}`,
                                {
                                  method: "PUT",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: requestBody,
                                }
                              );
                              const result = await response.json();
                              if (!response.ok) {
                                throw new Error(
                                  result.error ||
                                    "Failed to update the database"
                                );
                              }
                              alert(`${fieldName} updated successfully!`);
                              if (isDoc1) {
                                setDoc1Name(newName);
                              } else {
                                setDoc2Name(newName);
                              }
                            } catch (error) {
                              console.error("ERROR:", error);
                              alert(
                                "Failed to update the database. Please try again."
                              );
                            }
                          }
                        }}
                      >
                        {findDocNumber(keys)
                          ? doc1Name
                          : doc2Name || "Unnamed"}
                      </span>
                    </th>
                    {Object.entries(vals)
                      .slice(1)
                      .map(([key, val]) => (
                        <td id={key} key={key} style={containsPropVal}>
                          <td style={tdPropStyle}>{key}</td>
                          <td style={tdValueStyle}>{val}</td>
                        </td>
                      ))}

                  </tr>
                </>
              )}
            </tbody>
            <tbody>{renderProperNote("zzz" + keys)}</tbody>
            <tbody style={tbodyStyle}>
              <tr style={lastRow}>
                {renderAddNote("zzz" + keys)}
                <div>
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontStyle: "italic",
                      color: "#526666",
                      margin: "2px",
                    }}
                  >
                    <span
                      onClick={() => handleCheckedMatched("zzz" + keys)}
                      style={{
                        display: "inline-block",
                        width: "16px",
                        height: "16px",
                        marginRight: "8px",
                        border: "2px solid #526666",
                        borderRadius: "4px",
                        backgroundColor: handleStayCheckedMatched("zzz" + keys)
                          ? "#526666" // Checked state color
                          : "transparent",
                        cursor: "pointer",
                        position: "relative",
                        padding: "0 2px",
                      }}
                    >
                      {handleStayCheckedMatched("zzz" + keys) && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-8px",
                            left: "0px",
                            fontSize: "24px",
                            color: "white", // Color for the checkmark
                            padding: "0 0 0 2px",
                            margin: "0",
                          }}
                        >
                          ✔
                        </span>
                      )}
                    </span>
                    {handleStayCheckedMatched("zzz" + keys)
                      ? "Confirmed No-Match"
                      : "Potential No-Match"}
                  </label>

               
                  <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                    <span
                      onClick={() => handleCheckboxChange("zzz" + keys)}
                      style={{
                        display: 'inline-block',
                        width: '16px',
                        height: '16px',
                        marginRight: '8px', // Spacing the checkbox before the label
                        border: '2px solid #526666',
                        borderRadius: '4px',
                        backgroundColor: markForExport.has("zzz" + keys)
                          ? '#526666' // Checked state color
                          : 'transparent',
                        cursor: 'pointer',
                        position: 'relative',
                        padding: '0 2px'
                      }}
                    >
                      {markForExport.has("zzz" + keys) && (
                        <span
                          style={{
                            position: 'absolute',
                            top: '-8px',
                            left: '0px',
                            fontSize: '24px',
                            color: 'white', // Color for the checkmark
                            padding: '0 0 0 2px',
                            margin: '0'
                          }}
                        >
                          ✔
                        </span>
                      )}
                    </span>
                    Mark For Export
                  </label>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  }
}
}

  function noMatchesEndOfResultsB(lineItemZero) {
    if (data["no_match_a"] != null && data["no_match_b"] != null) {
      let a = JSON.parse(data["no_match_a"]);
      let b = JSON.parse(data["no_match_b"]);

      if (Object.keys(b).length > 0) {
        return Object.entries(b).map(([keys, vals]) => {
          const showDoc2 = doc2Visibility[keys] || false;
          const totalEntry = Object.entries(vals).find(
            ([k]) => k.toLowerCase() === "total"
          );
          return (
            <div
              className="redZone"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                borderBottom: "2px solid rgb(0, 179, 181)",
                padding: "0px 0px 10px 0px",
                margin: "5px 0px 10px 0px",
                backgroundColor: "#ffffff",
              }}
            >
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  top: "40px",
                  left: "0%",
                  overflow: "hidden",
                  zIndex: "9999",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "rgb(0, 179, 181)",
                  pointerEvents: "none",
                  fontSize: "20px",
                }}
              >
                <img src={Stop} />
              </a>

              <table style={endOfDocTable} id={"zzz" + keys}>
                <thead style={theadStyle}>
                  <tr>
                    <th>
                      NOT FOUND IN ANY ZONE
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: "13px",
                          color: "#526666",
                          padding: "2px 0px",
                          display: "inline",
                          margin: "2px",
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleDoc2Visibility(keys)}
                      >
                        Expand
                        <span
                          style={{
                            marginLeft: "8px",
                            display: "inline-block",
                            width: "0",
                            height: "0",
                            borderTop: "4px solid transparent",
                            borderBottom: "4px solid transparent",
                            borderLeft: "8px solid #526666",
                            transform: doc2Visibility[keys]
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                            transformOrigin: "4px 50%",
                            transition: "transform 0.2s ease",
                          }}
                        ></span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody id={"string"} key={keys} style={tbodyStyle}>
                  <tr style={trStyle}>
                    {Object.entries(vals)
                      .slice(0, 1)
                      .map(([key, val]) => {
                        const totalEntry = Object.entries(vals).find(
                          ([k]) => k.toLowerCase() === "total"
                        );

                        return (
                          <>
                            <th
                              style={{
                                ...thStyle,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "90%",
                              }}
                              key={key}
                            >
                              <span title={`${key}: ${val}`}>
                                {doc2Visibility[keys]
                                  ? `${key}: ${val}`
                                  : `${
                                      key.length > 20
                                        ? key.substring(0, 20) + "..."
                                        : key
                                    }` +
                                    `: ${
                                      val.length > 30
                                        ? val.substring(0, 30) + "..."
                                        : val
                                    }`}
                              </span>

                              {!doc2Visibility[keys] && totalEntry && (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  <span style={{ color: "#00B3B5" }}>
                                    Total:
                                  </span>
                                  <span style={tdValueStyle}>
                                    {totalEntry[1]}
                                  </span>
                                </span>
                              )}
                            </th>
                          </>
                        );
                      })}
                  </tr>
                  {doc2Visibility[keys] && (
                    <>
                      <tr style={trStyle}>
                        <th style={verticalLabel}>
                          {findDocNumber(keys)}
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#00B3B5",
                              textDecoration: "underline",
                              zIndex: "1000",
                            }}
                            className={findDocNumber(keys) ? "doc1" : "doc2"}
                            onClick={async () => {
                              const isDoc1 = findDocNumber(keys);
                              const fieldName = isDoc1
                                ? "doc1_name"
                                : "doc2_name";
                              const currentName = isDoc1
                                ? data?.doc1_name
                                : data?.doc2_name;
                              const newName = window.prompt(
                                `Enter a new name for ${fieldName}:`,
                                currentName || "Unnamed"
                              );
                              if (newName !== null && newName.trim() !== "") {
                                try {
                                  const claimId = comparisonID;
                                  if (!claimId) {
                                    console.error(
                                      "ERROR: Claim ID is missing!"
                                    );
                                    alert("Error: Claim ID is missing.");
                                    return;
                                  }
                                  const requestBody = JSON.stringify({
                                    [fieldName]: newName,
                                  });
                                  const response = await fetch(
                                    `${process.env.REACT_APP_DOCNAMES}/${claimId}`,
                                    {
                                      method: "PUT",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: requestBody,
                                    }
                                  );
                                  const result = await response.json();
                                  if (!response.ok) {
                                    throw new Error(
                                      result.error ||
                                        "Failed to update the database"
                                    );
                                  }
                                  alert(`${fieldName} updated successfully!`);
                                  if (isDoc1) {
                                    setDoc1Name(newName);
                                  } else {
                                    setDoc2Name(newName);
                                  }
                                } catch (error) {
                                  console.error("ERROR:", error);
                                  alert(
                                    "Failed to update the database. Please try again."
                                  );
                                }
                              }
                            }}
                          >
                            {findDocNumber(keys)
                              ? doc1Name
                              : doc2Name || "Unnamed"}
                          </span>
                        </th>
                        {Object.entries(vals)
                          .slice(1)
                          .map(([key, val]) => (
                            <td id={key} key={key} style={containsPropVal}>
                              <td style={tdPropStyle}>{key}</td>
                              <td style={tdValueStyle}>{val}</td>
                            </td>
                          ))}
                      </tr>
                    </>
                  )}
                </tbody>
                <tbody>{renderProperNote("zzz" + keys)}</tbody>
                <tbody style={tbodyStyle}>
                  <tr style={lastRow}>
                    {renderAddNote("zzz" + keys)}
                    <div>
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontStyle: "italic",
                          color: "#526666",
                          margin: "2px",
                        }}
                      >
                        <span
                          onClick={() => handleCheckedMatched("zzz" + keys)}
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                            border: "2px solid #526666",
                            borderRadius: "4px",
                            backgroundColor: handleStayCheckedMatched("zzz" + keys)
                              ? "#526666" // Checked state color
                              : "transparent",
                            cursor: "pointer",
                            position: "relative",
                            padding: "0 2px",
                          }}
                        >
                          {handleStayCheckedMatched("zzz" + keys) && (
                            <span
                              style={{
                                position: "absolute",
                                top: "-8px",
                                left: "0px",
                                fontSize: "24px",
                                color: "white", // Color for the checkmark
                                padding: "0 0 0 2px",
                                margin: "0",
                              }}
                            >
                              ✔
                            </span>
                          )}
                        </span>
                        {handleStayCheckedMatched("zzz" + keys)
                          ? "Confirmed No-Match"
                          : "Potential No-Match"}
                      </label>
                      <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                        <span
                          onClick={() => handleCheckboxChange("zzz" + keys)}
                          style={{
                            display: 'inline-block',
                            width: '16px',
                            height: '16px',
                            marginRight: '8px', // Spacing the checkbox before the label
                            border: '2px solid #526666',
                            borderRadius: '4px',
                            backgroundColor: markForExport.has("zzz" + keys)
                              ? '#526666' // Checked state color
                              : 'transparent',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: '0 2px'
                          }}
                        >
                          {markForExport.has("zzz" + keys) && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '0px',
                                fontSize: '24px',
                                color: 'white', // Color for the checkmark
                                padding: '0 0 0 2px',
                                margin: '0'
                              }}
                            >
                              ✔
                            </span>
                          )}
                        </span>
                        Mark For Export
                      </label>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        });
      }
    }
  }

  
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(percentValue * 100) / MAX}% 100%`,
    };
  }

  function validateRCV(val) {
    if (val === null) {
      return
    }
    else if (parseInt(val) < 0) {
      return (
        <td className="differentials grey" key={val}>RCV - Total: <strong className="green">{(val.toFixed(2)) * (-1)}</strong></td>
      )
    }
    else if (parseInt(val) > 0) {
      return (
        <td className="differentials grey" key={val}>RCV - Total: <strong className="red">{val.toFixed(2) * (-1)}</strong></td>
      )
    }
  }

  function validateACV(val) {
    if (val == null) {
      return


    }
    else if (Math.sign(val) === -1) {
      return (
        <td className="differentials grey" key={val}>ACV - Total: <strong className="green">{val.toFixed(2) * (-1)}</strong></td>
      )
    }
    else {
      return (
        <td className="differentials grey" key={val}>ACV - Total: <strong className="red">{(val.toFixed(2)) * (-1)}</strong></td>
      )
    }
  }

  function validateTotal(key, val) {
    const parsedVal = parseInt(val)
    if (key != "Description Match Percent") {
      if (val == null) {
        return
      }
      else if (Math.sign(val) === -1) {
        return (
          <td className="differentials grey" key={val}>Total - Total: <strong className="red">{val.toFixed(2)}</strong></td>
        )
      }
      else {
        return (
          <td className="differentials grey" key={val}>Total - Total: <strong className="green">{val.toFixed(2)}</strong></td>
        )
      }
    }
    else {
      return (
        <td className="differentials grey" key={parsedVal}>Total - Total: <strong>N/A</strong></td>
      )
    }

  }

  const handleFilterChange = event => {
    setFilter(event.target.value)
  }

  if (data["claimData"] != null) {
    let resultsLayer = data[currentSection]
    let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0, 1).toString())
    let noZone1Duplicates = [...new Set(resLayer1)]
    let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
    let noZone2Duplicates = [...new Set(resLayer2)]
    doc1ZoneState.push(...noZone1Duplicates)
  }

  const handleSelect = (zone1Name, e) => {
    setNewZonesToCompare(current =>
      current.map(obj => {
        if (obj.Doc1 === zone1Name) {
          return { ...obj, SubZones: [e] };
        }
        return obj;
      })
    )
  }

  // Check if the container should stay checked
  const handleNewStayChecked = (container) => newViewArray.includes(container);

// Handle checkbox marking/unmarking
const handleNewChecked = (container) => {
  // Update newViewArray using Set
  setNewViewArray((prevSet) => {
    const updatedSet = new Set(prevSet);
    if (updatedSet.has(container)) {
      updatedSet.delete(container);
    } else {
      updatedSet.add(container);
    }
    return updatedSet;
  });

  // Update markForExport using Set
  setMarkForExport((prevSet) => {
    const updatedSet = new Set(prevSet);
    if (updatedSet.has(container)) {
      updatedSet.delete(container);
    } else {
      updatedSet.add(container);
    }
    return updatedSet;
  });
};


const confirmExportSelection = () => {
  //console.log("markForExport.size: " + markForExport.size);
  if (markForExport.size > 0) {
    // Convert the Set to an array and add to confirmedForExport
    setConfirmedForExport((prevSet) => new Set([...prevSet, ...markForExport]));

    // Add confirmed elements to pdfCopy
    const pdfCopy = document.getElementById('pdfCopy');
    const fragment = document.createDocumentFragment();  // Using DocumentFragment to minimize reflows

    // Create a parent div to wrap all containers
    const containerWrapper = document.createElement('div');
    containerWrapper.style.border = "4px solid #00B3B5";
    containerWrapper.style.boxSizing = "border-box";
    containerWrapper.style.width = "100%";
    containerWrapper.style.padding = "16px"; // Optional padding around the group of containers
    containerWrapper.style.marginBottom = "80px"; // Adjust the margin as needed
    containerWrapper.style.marginTop = "80px"; // Adjust the margin as needed

    // Iterate through the Set and process each key
    markForExport.forEach((key) => {
      const containerElement = document.getElementById(key);

      if (containerElement) {
        const clone = containerElement.cloneNode(true);
        clone.style.width = '100%';
        clone.style.boxSizing = "border-box";

        // Sanitize the ID to ensure it's a valid CSS selector
        const sanitizedId = key.replace(/[^a-zA-Z0-9-_]/g, '-');
        clone.id = `${sanitizedId}-pdfCopy`; // Unique ID for cloned element

        containerWrapper.appendChild(clone); // Append each cloned element to the container wrapper
      }
    });

    // Append the entire group of cloned containers inside the border to pdfCopy
    pdfCopy.appendChild(containerWrapper);

    // Clear the temporary selection Sets
    setMarkForExport(new Set());
    setNewViewArray(new Set());
  } else {
    alert("No selections made.");
  }
};

function handleSectionExport(id) {
  console.log(`Processing section export for ID: ${id}`);

  const element = document.getElementById(id);
  if (!element) {
    console.log(`Element with ID ${id} not found.`);
    return;
  }

  // Move up two levels to reach the grandparent container
  let grandparentDiv = element.parentElement?.parentElement?.parentElement;

  // Ensure the found element is a <div>
  if (!grandparentDiv || grandparentDiv.tagName !== "DIV") {
    console.log(`No valid grandparent div found for ${id}.`);
    return;
  }

  // Grab all tables inside this grandparent div
  const tableIds = [...grandparentDiv.querySelectorAll("table[id]")].map(
    (table) => table.id
  );

  console.log(`Found ${tableIds.length} tables in grandparent div for ${id}:`, tableIds);

  setMarkAllCheckboxes((prevSet) => {
    const updatedMarkAll = new Set(prevSet);
    const isRemoving = updatedMarkAll.has(id);

    if (isRemoving) {
      console.log(`Removing ${id} from marked sections.`);
      updatedMarkAll.delete(id);
    } else {
      console.log(`Adding ${id} to marked sections.`);
      updatedMarkAll.add(id);
    }

    tableIds.forEach((tableId) => {
      setMarkForExport((prevSet) => {
        const updatedSet = new Set(prevSet);
        if (isRemoving) {
          console.log(`Removing table ${tableId} from export list.`);
          updatedSet.delete(tableId);
        } else {
          console.log(`Adding table ${tableId} to export list.`);
          updatedSet.add(tableId);
        }
        return updatedSet;
      });

      requestAnimationFrame(() => handlePDFClone(tableId, isRemoving));
    });

    return updatedMarkAll;
  });

  console.log(`Completed processing for ${id}.`);
  return tableIds;
}


function handlePDFClone(tableId, isRemoving) {
  const element = document.getElementById(tableId);
  if (!element) return;

  const pdfCopy = document.getElementById("pdfCopy");
  if (!pdfCopy) return;

  const cloneId = `${tableId}-pdfCopy`;
  const existingClone = pdfCopy.querySelector(`#${CSS.escape(cloneId)}`);

  if (isRemoving) {
    existingClone && pdfCopy.removeChild(existingClone);
  } else if (!existingClone) {
    const elementClone = element.cloneNode(true);
    elementClone.id = cloneId;
    elementClone
      .querySelectorAll("button, input[type='checkbox'], label")
      .forEach((el) => el.remove());

    pdfCopy.appendChild(elementClone);
  }
}


const handleCheckboxChange = (lineItemZero) => {
  //console.log("handleCheckboxChange called with:", lineItemZero);

  setMarkForExport((prevSet) => {
    //console.log("Previous markForExport set:", prevSet);
    const updatedSet = new Set(prevSet);
    
    if (updatedSet.has(lineItemZero)) {
      //console.log("Removing from markForExport:", lineItemZero);
      updatedSet.delete(lineItemZero);
    } else {
      //console.log("Adding to markForExport:", lineItemZero);
      updatedSet.add(lineItemZero);
    }

    //console.log("Updated markForExport set:", updatedSet);
    return updatedSet;
  })

  requestAnimationFrame(() => {
    //console.log("Executing requestAnimationFrame callback");

    const element = document.getElementById(lineItemZero);
    console.log("Element found by lineItemZero:", element);

    const pdfCopy = document.getElementById('pdfCopy');
    //console.log("pdfCopy container:", pdfCopy);

    if (element) {
      const cloneId = `${lineItemZero}-pdfCopy`;
      //console.log("Clone ID:", cloneId);

      const elementClone = element.cloneNode(true);
      elementClone.id = cloneId;
      //console.log("Cloned Element:", elementClone);

      // Remove unwanted elements from the clone
      elementClone.querySelectorAll('button, input[type="checkbox"], label').forEach(el => {
        //console.log("Removing element from clone:", el);
        el.remove();
      });

      if (markForExport.has(lineItemZero)) {
        const existingClone = pdfCopy.querySelector(`#${CSS.escape(cloneId)}`);
        console.log("Existing clone in pdfCopy:", existingClone);

        if (existingClone) {
          //console.log("Removing existing clone from pdfCopy.");
          pdfCopy.removeChild(existingClone);
        }
      } else {
        if (!pdfCopy.querySelector(`#${CSS.escape(cloneId)}`)) {
          //console.log("Appending cloned element to pdfCopy.");
          pdfCopy.appendChild(elementClone);
        } else {
          console.log("Clone already exists in pdfCopy, skipping append.");
        }
      }
    }
  });
};

// Handle checked items for matched export (same logic for removing buttons, checkboxes, and labels)
const handleCheckedMatched = (lineItemZero) => {
  console.log("handleCheckedMatched: " + lineItemZero);

  // Toggle the presence of `lineItemZero` in the markConfirm Set
  setMarkConfirm((prevSet) => {
    const newSet = new Set(prevSet);
    if (newSet.has(lineItemZero)) {
      newSet.delete(lineItemZero);
    } else {
      newSet.add(lineItemZero);
    }
    return newSet;
  });

  // Similarly, toggle the presence of `lineItemZero` in the markForExport Set
  setMarkForExport((prevSet) => {
    const newSet = new Set(prevSet);
    if (newSet.has(lineItemZero)) {
      newSet.delete(lineItemZero);
    } else {
      newSet.add(lineItemZero);
    }
    return newSet;
  });

  // Properly escape the ID to maintain spaces
  const escapedId = CSS.escape(lineItemZero);
  //console.log("Escaped ID:", escapedId);

  // Clone and add to pdfCopy for the matched export
  const element = document.getElementById(escapedId);
  const pdfCopy = document.getElementById('pdfCopy');

  if (element) {
    const elementClone = element.cloneNode(true);
    const cloneId = `${escapedId}-pdfCopy`; // Make the ID unique

    // Remove all button elements from the clone
    elementClone.querySelectorAll('button').forEach((button) => button.remove());

    // Remove all checkbox input elements from the clone
    elementClone.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => checkbox.remove());

    // Remove all label elements from the clone
    elementClone.querySelectorAll('label').forEach((label) => label.remove());

    if (markForExport.has(lineItemZero)) {
      // Remove from pdfCopy if the element is unmarked
      const existingClone = pdfCopy.querySelector(`#${CSS.escape(cloneId)}`);
      if (existingClone) {
        pdfCopy.removeChild(existingClone);
      }
    } else {
      // Add cloned element to pdfCopy if it's not already there
      if (!pdfCopy.querySelector(`#${CSS.escape(cloneId)}`)) {
        elementClone.id = cloneId; // Set unique ID for the cloned element
        pdfCopy.appendChild(elementClone); // Append to pdfCopy
      }
    }
  }
};



const handleStayChecked = (lineItemZero) => markForExport.has(lineItemZero);

const handleStayCheckedMatched = (lineItemZero) => {
  return 
};


function returnDoc2Zones(val) {
  return val
}

  function returnSubZonesArray(zone1Name, index) {
    if (newZonesToCompare[index]["SubZones"].length === 0) {
      return bestMatch(zone1Name)
    }
    else {
      return (
        newZonesToCompare[index]["SubZones"][0].map((val, i) => {
          return (val.name + "\t")
        })
      )
    }
  }

  function findDocNumber(key1) {
    if (key1 % 2 === 0) {
      return true
    }
    else {
      return false
    }
  }

 function dynamicPercentColorChange(val, totals) {
  const green = {
    position: 'relative',
    height: 'auto',
    width: '100%',
    backgroundColor: '#6CE20C80',
    borderRadius: '4px',
    padding: '0px 0px',
    margin: '10px 0px',
    overflow: 'auto',
    border: '1px dotted black'
  };

  const yellow = {
    position: 'relative',
    height: 'auto',
    width: '100%',
    backgroundColor: '#DDD3A0',
    borderRadius: '4px',
    padding: '0px 0px',
    margin: '10px 0px',
    overflow: 'auto',
    border: '1px dotted black'
  };

  const zero = {
    position: 'relative',
    height: 'auto',
    width: '100%',
    backgroundColor: '#438D0780',  // darker green
    borderRadius: '4px',
    padding: '0px 0px',
    margin: '10px 0px',
    overflow: 'auto',
    border: '1px dotted black'
  };
  const keys = Object.keys(totals);

  if (val === 100 || (totals[keys[1]] == 0 && val >= 60)) {    
    return zero;
  } else if (val >= 43) {
    return green;
  } else {
    return yellow;
  }
}



  // Example Usage:
  const val1 = 0; // Replace this with your actual value
  document.addEventListener("DOMContentLoaded", () => hideRedZoneIfValueIsZero(val1));


/* const [doc2Visibility, setDoc2Visibility] = React.useState({});

  const toggleDoc2Visibility = (key) => {
    setDoc2Visibility((prevState) => ({
      ...prevState,
      [key]: !prevState[key],  // Toggle visibility per item
    }));
  }; */

  function renderZones() {

  if(data["claimData"] && data["results"]){ 
    if(((Object.keys(data.full_results)).length === 0) || ((Object.keys(data.results)).length === 0)) {
      return (
        <div>
          <p className={'loading-comparisons-text'}> Insufficient Results to Display... </p>
          <p> Data may not have been parsed properly from one of the two documents. </p>
        </div>
      ) 
    }  

      let resultsLayer = data[currentSection]
      let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0, 1).toString())
      let noZone1Duplicates = [...new Set(resLayer1)]
      let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
      let noZone2Duplicates = [...new Set(resLayer2)]
      doc1ZoneState.push(...noZone1Duplicates)
      let dropdown = noZone2Duplicates.map((zone2Name, index) => {
        return ({
          name: zone2Name,
          id: index
        })
      }

      )

      if (newZonesToCompare.length > 0) {
        return (
          <div>
            <div style={getZonePairingsContainer}>
              {noZone1Duplicates.map((zone1Name, index) =>
                <div style={mapStyle} key={index}>

                  <div>
                    <label style={labelStyle}>Showing Zone From Document 1: </label>
                    <label style={zoneStyle}>{zone1Name}</label>
                  </div>
                  <div>
                    <label style={labelStyle}>Showing Zone From Document 2: </label>
                    <label style={zoneStyle}>{returnSubZonesArray(zone1Name, index)}</label>
                    <Multiselect
                      options={dropdown}
                      key={zone1Name}
                      id={zone1Name}
                      style={'dropdown-style'}
                      onSelect={(e) => handleSelect(zone1Name, e)}
                      onRemove={(e) => handleSelect(zone1Name, e)}
                      hidePlaceholder={true}
                      placeholder={" Change Zone(s) "}
                      isMulti
                      displayValue="name"
                    />
                  </div>
                  <div>
                    {Object.entries(resultsLayer).map(([zero, zones]) => {
                      const itemKey = zones["Zone 1"] + " vs. " + zones["Zone 2"] + " ";
                      const showDoc2 = doc2Visibility[itemKey] || false;
                      if (
                        (newZonesToCompare[index]["SubZones"].length === 0) &&
                        (Object.values(zones).slice(0, 1).includes(zone1Name)) &&
                        (Object.values(zones).slice(1, 2).includes(bestMatch(zone1Name).toString()))
                      ) {
                        return (
                        <div>
                         


                        <div>
{/* ------------------------------------------------------------------------------------------------------------- */}
                          {Object.entries(zones).slice(3, 4).some(([lineItems, lineItemsObject]) =>
                          Object.entries(lineItemsObject).length > 0
                          ) && (
                              <div
                                className="redZone"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  boxSizing: 'border-box',
                                  width: '100%',
                                  overflowX: 'hidden',
                                  borderBottom: '2px solid rgb(0, 179, 181)',
                                  margin: '40px 0px 0px 0px',
                                  backgroundColor: '#ffffff',
                                  maxHeight: doc2Visibility[itemKey + "_red"] ? '150px' : 'unset',
                                  overflowY: doc2Visibility[itemKey + '_red'] ? 'hidden' : 'auto',
                                }}
                              >  
                            <div style={{
                              display: 'flex',
                              position: 'inline',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: '0px',
                            }}>                           
                          <label style={{position: "relative",
                            top: "0px",
                            bottom: "0px",
                            right: "7.5%",
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            fontStyle: "italic",
                            color: "rgb(82, 102, 102)",
                          }}
                          >
                            <span
                              onClick={() => handleSectionExport(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                              style={{
                                display: 'inline-block',
                                width: '16px',
                                height: '16px',
                                marginRight: '8px', // Spacing the checkbox before the label
                                border: '2px solid #526666',
                                borderRadius: '4px',
                                backgroundColor: markAllCheckboxes.has(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                  ? '#526666' // Checked state color
                                  : 'transparent',
                                cursor: 'pointer',
                                position: 'relative',
                                padding: '0 2px'
                              }}
                              id={`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                            >
                              {markAllCheckboxes.has(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                <span
                                  style={{
                                    position: 'absolute',
                                    top: '-8px',
                                    left: '0px',
                                    fontSize: '24px',
                                    color: 'white', // Color for the checkmark
                                    padding: '0 0 0 2px',
                                    margin: '0'
                                  }}
                                >
                                  ✔
                                </span>
                              )}
                            </span>
                            Mark Section For Export
                          </label>
                          <section
                            style={{
                              fontStyle: 'italic',
                              fontSize: '13px',
                              color: '#526666',
                              padding: '2px 0px',
                              display: 'inline',
                              alignItems: 'center',
                              margin: '2px',
                              cursor: 'pointer',
                              position: 'relative',
                              width: '10%',
                              overflowY: "hidden" ,
                              overflowX: "hidden",
                            }}
                            onClick={() => toggleDoc2Visibility(itemKey + "_red")}
                            >
                            {doc2Visibility[itemKey + "_red"] ? 'Expand Section' : 'Collapse Section'}
                            <span
                              style={{
                                marginLeft: '8px',
                                display: 'inline-block',
                                width: '0',
                                height: '0',
                                borderTop: '4px solid transparent',
                                borderBottom: '4px solid transparent',
                                borderLeft: '8px solid #526666',
                                transform: doc2Visibility[itemKey + "_red"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                transformOrigin: '4px 50%',
                                transition: 'transform 0.2s ease',
                              }}
                            ></span>
                          </section>
                          </div>
                                <a
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    marginTop: '-60px',
                                    marginLeft: '-53px',
                                    top: '40px',
                                    left: '0%',
                                    zIndex: '9999',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: 'rgb(0, 179, 181)',
                                    pointerEvents: 'none',
                                    fontSize: '20px',
                                  }}
                                >
                                  <img src={Stop}
                                  style={{
                                    maxWidth: '70px', // adjust as needed
                                    maxHeight: '70px', // adjust as needed
                                    objectFit: 'contain'
                                  }} />
                                </a>

                                {Object.entries(zones)
                                  .slice(3, 4)
                                  .map(([lineItems, lineItemsObject]) => (
                                    <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden', maxHeight: showDoc2 ? '100px' : 'unset', overflowY: showDoc2 ? 'hidden' : 'hidden'}} key={lineItems}>
                                      {Object.entries(lineItemsObject).map(([key1, val1]) => (
                                        <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} 
                                        style={noMatchTableStyle}>
                                        <thead>

                                          <th style={theadStyle}>
                                            {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed" : "Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          
                                          <div
                                            style={{
                                              fontStyle: 'italic',
                                              fontSize: '13px',
                                              color: '#526666',
                                              padding: '2px 0px',
                                              display: 'inline',
                                              margin: '2px',
                                              float: 'right',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => toggleDoc2Visibility(key1)}
                                          >
                                            Expand
                                            <span
                                              style={{
                                                marginLeft: '8px',
                                                display: 'inline-block',
                                                width: '0',
                                                height: '0',
                                                borderTop: '4px solid transparent',
                                                borderBottom: '4px solid transparent',
                                                borderLeft: '8px solid #526666',
                                                transform: doc2Visibility[key1] ? 'rotate(90deg)' : 'rotate(0deg)',
                                                transformOrigin: '4px 50%',
                                                transition: 'transform 0.2s ease',
                                              }}
                                            ></span>
                                          </div>
                                          </th>

                                        </thead>
                                            <tbody style={tbodyStyle} key={key1}>
                                            {Object.entries(val1).slice(0, 1).map(([key2, val2]) => {
                                              
                                              const totalEntry = Object.entries(val1).find(([key]) => key.toLowerCase() === 'total');
                                              
                                              return (
                                                <tr style={trStyle} key={key2}>
                                                  <th style={{...thStyle, display: "flex", justifyContent: "space-between", alignItems: "center", width:'90%' }}>
                                                  <span title={key2 + ": " + val2}>
                                                    {doc2Visibility[key1]
                                                      ? `${key2}: ${val2}`  
                                                      : `${key2.length > 20 ? key2.substring(0, 20) + "..." : key2}` + 
                                                        `: ${val2.length > 30 ? val2.substring(0, 30) + "..." : val2}`
                                                    }
                                                  </span>

                                                    {!doc2Visibility[key1] && totalEntry && (
                                                      <span style={{ fontWeight: "bold", textAlign: "right"}}>
                                                        <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                        <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                      </span>
                                                    )}
                                                  </th>
                                                </tr>
                                              );
                                            })}
                                            {doc2Visibility[key1] &&(
                                              <tr key={key1} style={trStyle}>
                                                <th style={verticalLabel}>
                                                  {findDocNumber(key1)}
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className={findDocNumber(key1) ? "doc1" : "doc2"}
                                                    onClick={async () => {
                                                      const isDoc1 = findDocNumber(key1);
                                                      const fieldName = isDoc1 ? "doc1_name" : "doc2_name";
                                                      const currentName = isDoc1 ? data?.doc1_name : data?.doc2_name;
                                                      const newName = window.prompt(`Enter a new name for ${fieldName}:`, currentName || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ [fieldName]: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                          alert(`${fieldName} updated successfully!`);
                                                          if (isDoc1) {
                                                            setDoc1Name(newName);
                                                          } else {
                                                            setDoc2Name(newName);
                                                          }
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                    >
                                                    {findDocNumber(key1) ? doc1Name : doc2Name || "Unnamed"}
                                                  </span>
                                                </th>
                                                {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                                  return (
                                                    <td style={containsPropVal}>
                                                      <td style={tdPropStyle} key={key2}>{key2}</td>
                                                      <td style={tdValueStyle} key={val2}>{val2}</td>
                                                    </td>
                                                  )
                                                })}
                                              </tr>
                                              )}
                                            </tbody>
                                            <tbody>
                                              {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                            </tbody>
                                            <tbody style={tbodyStyle}>
                                              <tr style={lastRow}>

                                                {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                <div>
                                                  <label
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      fontStyle: "italic",
                                                      color: "#526666",
                                                      margin: "2px",
                                                    }}
                                                  >
                                                    <span
                                                      onClick={() => handleCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                      style={{
                                                        display: "inline-block",
                                                        width: "16px",
                                                        height: "16px",
                                                        marginRight: "8px",
                                                        border: "2px solid #526666",
                                                        borderRadius: "4px",
                                                        backgroundColor: handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                          ? "#526666" // Checked state color
                                                          : "transparent",
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        padding: "0 2px",
                                                      }}
                                                    >
                                                      {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) && (
                                                        <span
                                                          style={{
                                                            position: "absolute",
                                                            top: "-8px",
                                                            left: "0px",
                                                            fontSize: "24px",
                                                            color: "white", // Color for the checkmark
                                                            padding: "0 0 0 2px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          ✔
                                                        </span>
                                                      )}
                                                    </span>
                                                    {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                      ? "Confirmed No-Match"
                                                      : "Potential No-Match"}
                                                  </label>

                                                  <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                                                    <span
                                                      onClick={() => handleCheckboxChange("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                      style={{
                                                        display: 'inline-block',
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '8px', // Spacing the checkbox before the label
                                                        border: '2px solid #526666',
                                                        borderRadius: '4px',
                                                        backgroundColor: markForExport.has("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                          ? '#526666' // Checked state color
                                                          : 'transparent',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        padding: '0 2px'
                                                      }}
                                                    >
                                                      {markForExport.has("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) && (
                                                        <span
                                                          style={{
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            left: '0px',
                                                            fontSize: '24px',
                                                            color: 'white', // Color for the checkmark
                                                            padding: '0 0 0 2px',
                                                            margin: '0'
                                                          }}
                                                        >
                                                          ✔
                                                        </span>
                                                      )}
                                                    </span>
                                                    Mark For Export
                                                  </label>
                                                </div>
                                              </tr>
                                            </tbody>
                                        </table>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                          )}
{/* ------------------------------------------------------------------------------------------------------------- */}
                          {Object.entries(zones).slice(2, 3).map(([lineItems, lineItemsObject]) => {
                            const greenTables = [];
                            const yellowTables = [];
                            const zeroTables = [];
                            
                            <div ref={pdfRef} key={lineItems} id={lineItems}>
                              


                              {Object.entries(lineItemsObject)
                                .filter(object => object[1]["Percentage"] >= percentValue)
                                .sort(function (a, b) { return (a[1]["Percentage"] - b[1]["Percentage"]) })
                                .map(([lineItemZero, lineItemZeroObject], index) => {
                                  const percentage = Number(lineItemZeroObject["Percentage"]);
                                  const itemKey = zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero;
                                  const showDoc2 = doc2Visibility[itemKey] || false;
                                  const entries = Object.entries(lineItemZeroObject);
                                  const totalEntry = entries.find(([key]) => key.toLowerCase() === 'total');
                                  
                                  const tableContent = (
                                    <table style={dynamicPercentColorChange(percentage, totalEntry)}
                                      id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                      key={lineItemZero}>

                                      <thead>
                                        <th style={theadStyle}>

                                          {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})
                                          <div
                                            style={{
                                              fontStyle: 'italic',
                                              fontSize: '13px',
                                              color: '#526666',
                                              padding: '2px 0px',
                                              display: 'inline',
                                              margin: '2px',
                                              float: 'right',
                                              cursor: 'pointer',
                                              zIndex: '999'
                                            }}
                                            onClick={() => toggleDoc2Visibility(itemKey)}
                                          >
                                            Expand
                                            <span
                                              style={{
                                                marginLeft: '8px',
                                                display: 'inline-block',
                                                width: '0',
                                                height: '0',
                                                zIndex: '999',
                                                borderTop: '4px solid transparent',
                                                borderBottom: '4px solid transparent',
                                                borderLeft: '8px solid #526666',
                                                transform: showDoc2 ? 'rotate(90deg)' : 'rotate(0deg)',
                                                transformOrigin: '4px 50%',
                                                transition: 'transform 0.2s ease',
                                              }}
                                            ></span>
                                          </div>
                                        </th>
                                      </thead>

                                      {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                        <tbody style={tbodyStyle}
                                          key={lineItem1}>
                                         {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) => {
                                         
                                         const totalEntry = Object.entries(lineItem1PropsVals).find(([key]) => key.toLowerCase() === 'total');

                                          return (
                                            <tr style={trStyle} key={desc}>
                                            <th 
                                              style={{ 
                                              ...thStyle,
                                              display: "flex", 
                                              justifyContent: "space-between", 
                                              alignItems: "center", 
                                              width: "90%" 
                                            }}>
                                              <span title={`${desc}: ${descVal}`}>
                                                {showDoc2 
                                                  ? `${desc}: ${descVal}`
                                                  : `${desc.length > maxLength ? desc.slice(0, maxLength) + "..." : desc}` + 
                                                    `: ${descVal.length > maxLength ? descVal.slice(0, maxLength) + "..." : descVal}`
                                                }
                                              </span>
                                              {!showDoc2 && totalEntry && (
                                                <span style={{ 
                                                  display: "flex", 
                                                  alignItems: "center", 
                                                  justifyContent: "flex-end", 
                                                  fontWeight: "bold", 
                                                  minWidth: "120px"
                                                }}>
                                                  <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                  <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                </span>
                                              )}
                                              
                                            </th>
                                          </tr>
                                          
                                          );
                                        })}
                                          {showDoc2 &&(
                                                <>
                                                  <tr style={trStyle}>
                                                  <th style={verticalLabel}>
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className="doc1"
                                                    onClick={async () => {
                                                      const newName = window.prompt("Enter a new name:", data?.doc1_name || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ doc1_name: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                      
                                                          alert("Database updated successfully!");
                                                          const elements = document.getElementsByClassName('doc1');
                                                          setDoc1Name(newName)
                                                          
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    {doc1Name || "Unnamed"}
                                                  </span>
                                                  </th>                
                                                {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                                  <td style={containsPropVal}>
                                                    <td style={tdPropStyle} key={quantity}>{quantity}</td>
                                                    <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                                  </td>
                                                )}
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      )}

                                      {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                        <tbody style={tbodyStyle} key={lineItem2}>
                                         {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) => {
                                         
                                         const totalEntry = Object.entries(lineItem2PropsVals).find(([key]) => key.toLowerCase() === 'total');

                                          return (
                                            <tr style={trStyle} key={desc}>
                                            <th 
                                              style={{ 
                                              ...thStyle,
                                              display: "flex", 
                                              justifyContent: "space-between", 
                                              width: "90%" 
                                            }}>
                                          
                                              <span title={`${desc}: ${descVal}`}>
                                                {showDoc2 
                                                  ? `${desc}: ${descVal}` 
                                                  : `${desc.length > maxLength ? desc.slice(0, maxLength) + "..." : desc}` + 
                                                    `: ${descVal.length > maxLength ? descVal.slice(0, maxLength) + "..." : descVal}`
                                                }
                                              </span>
                                          
                                              {!showDoc2 && totalEntry && (
                                                <span style={{ 
                                                  display: "flex", 
                                                  justifyContent: "flex-end", 
                                                  fontWeight: "bold", 
                                                  minWidth: "120px"
                                                }}>
                                                  <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                  <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                </span>
                                              )}
                                              
                                            </th>
                                          </tr>
                                            
                                          );
                                        })}
                                          {showDoc2 &&(
                                              <>
                                                  <tr style={trStyle}>
                                                  <th style={verticalLabel}>
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className="doc2"
                                                    onClick={async () => {
                                                      const newName = window.prompt("Enter a new name:", data?.doc2_name || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ doc2_name: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                      
                                                          alert("Database updated successfully!");
                                                          const elements = document.getElementsByClassName('doc2');
                                                          setDoc2Name(newName)
                                                          
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    {doc2Name || "Unnamed"}
                                                  </span>

                                                  </th>                
                                                {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                                  <td style={containsPropVal}>
                                                    <td style={tdPropStyle} key={quantity}>{quantity}</td>
                                                    <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                                  </td>
                                                )}
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      )}

                                      <tbody style={tbodyStyle}>
                                        <tr style={trStyleFlexStart}>
                                          {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>
                                            validateRCV(val)
                                          )}
                                          {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>
                                            validateACV(val)
                                          )}
                                          {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>
                                            validateTotal(key, val)
                                          )}
                                        </tr>
                                      </tbody>

                                      <tbody>
                                        {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                      </tbody>

                                      <tbody style={tbodyStyle}>

                                        <tr style={lastRow}>

                                          {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                          <td>
                                            <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                                              <span
                                                onClick={() => handleCheckboxChange(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`)}
                                                style={{
                                                  display: 'inline-block',
                                                  width: '16px',
                                                  height: '16px',
                                                  marginRight: '8px', // Spacing the checkbox before the label
                                                  border: '2px solid #526666',
                                                  borderRadius: '4px',
                                                  backgroundColor: markForExport.has(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`)
                                                    ? '#526666' // Checked state color
                                                    : 'transparent',
                                                  cursor: 'pointer',
                                                  position: 'relative',
                                                  padding: '0 2px'
                                                }}
                                              >
                                                {markForExport.has(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`) && (
                                                  <span
                                                    style={{
                                                      position: 'absolute',
                                                      top: '-8px',
                                                      left: '0px',
                                                      fontSize: '24px',
                                                      color: 'white', // Color for the checkmark
                                                      padding: '0 0 0 2px',
                                                      margin: '0'
                                                    }}
                                                  >
                                                    ✔
                                                  </span>
                                                )}
                                              </span>
                                              Mark For Export
                                            </label>
                                          </td>
                                          
                                        </tr>
                                      </tbody>
                                    </table>
                                  );                
                                  if (totalEntry && totalEntry[1] == 0) {
                                    // Only push this specific tableContent to zeroTables if its total is zero.
                                    zeroTables.push(tableContent);
                                  } else if (percentage >= 40) {
                                    greenTables.push(tableContent);
                                  } else {
                                    yellowTables.push(tableContent);
                                  }
                                })}
                            </div>
                            return (
                              <div ref={pdfRef} key={lineItems} id={lineItems}>

{/* ------------------------------------------------------------------------------------------------------------- */}

                                {yellowTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + '_yellow'] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + '_yellow'] ? 'hidden' : 'auto'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-35px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={Yield} 
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}}/></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '40px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '44px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + '_yellow')}
                                    >
                                    {doc2Visibility[itemKey + '_yellow'] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + '_yellow'] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {yellowTables}
                                  </div>
                                )}

{/* ------------------------------------------------------------------------------------------------------------- */}
                              
                                  {greenTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + "_green"] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + "_green"] ? 'hidden' : 'auto'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-20px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      marginTop: '-40px',
                                      top: '40px',
                                      left: '0%',
                                      zIndex: '9999',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={Close}
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}} /></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '19px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '20px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + "_green")}
                                    >
                                    {doc2Visibility[itemKey + "_green"] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + "_green"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {greenTables}
                                  </div>
                                )}
{/* ------------------------------------------------------------------------------------------------------------- */}
                                  {zeroTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + "_zero"] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + "_zero"] ? 'hidden' : 'hidden'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-20px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      marginTop: '-40px',
                                      top: '40px',
                                      left: '0%',
                                      zIndex: '9999',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={GO}
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}} /></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '19px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '20px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + "_zero")}
                                    >
                                    {doc2Visibility[itemKey + "_zero"] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + "_zero"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {zeroTables}
                                  </div>
                                )}

{/* ------------------------------------------------------------------------------------------------------------- */}
                              </div>
                            );
                          })}
{/* ------------------------------------------------------------------------------------------------------------- */}
                        </div>
                        </div>
                        
                        )

                      } else if ((newZonesToCompare[index]["SubZones"].length > 0)) {
                        let subzones = (newZonesToCompare[index]["SubZones"]).map((obj, index) => {
                          return (
                            obj.map((obj2, index) => {
                              return obj2.name
                            })
                          )
                        })
                        for (let i = 0; i < subzones[0].length; i++) {
                          if (
                            (Object.values(zones).slice(0, 1).includes(zone1Name)) &&
                            (Object.values(zones).slice(1, 2).includes(subzones[0][i]))
                          ) {
                            return (
                              <div>
{/* ------------------------------------------------------------------------------------------------------------- */}
                          <label style={doc2ZoneStyle}>{returnDoc2Zones(zones["Zone 2"])}</label>
                          {Object.entries(zones).slice(3, 4).some(([lineItems, lineItemsObject]) =>
                          Object.entries(lineItemsObject).length > 0
                          ) && (
                              <div
                                className="redZone"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  boxSizing: 'border-box',
                                  width: '100%',
                                  overflowX: 'hidden',
                                  borderBottom: '2px solid rgb(0, 179, 181)',
                                  margin: '40px 0px 0px 0px',
                                  backgroundColor: '#ffffff',
                                  maxHeight: doc2Visibility[itemKey + "_red"] ? '150px' : 'unset',
                                  overflowY: doc2Visibility[itemKey + '_red'] ? 'hidden' : 'auto',
                                }}
                              >  
                            <div style={{
                              display: 'flex',
                              position: 'inline',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: '0px',
                            }}>                           
                          <label style={{position: "relative",
                            top: "0px",
                            bottom: "0px",
                            right: "7.5%",
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            fontStyle: "italic",
                            color: "rgb(82, 102, 102)",
                          }}
                          >
                            <span
                              onClick={() => handleSectionExport(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                              style={{
                                display: 'inline-block',
                                width: '16px',
                                height: '16px',
                                marginRight: '8px', // Spacing the checkbox before the label
                                border: '2px solid #526666',
                                borderRadius: '4px',
                                backgroundColor: markAllCheckboxes.has(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                  ? '#526666' // Checked state color
                                  : 'transparent',
                                cursor: 'pointer',
                                position: 'relative',
                                padding: '0 2px'
                              }}
                              id={`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                            >
                              {markAllCheckboxes.has(`Red ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                <span
                                  style={{
                                    position: 'absolute',
                                    top: '-8px',
                                    left: '0px',
                                    fontSize: '24px',
                                    color: 'white', // Color for the checkmark
                                    padding: '0 0 0 2px',
                                    margin: '0'
                                  }}
                                >
                                  ✔
                                </span>
                              )}
                            </span>
                            Mark Section For Export
                          </label>
                          <section
                            style={{
                              fontStyle: 'italic',
                              fontSize: '13px',
                              color: '#526666',
                              padding: '2px 0px',
                              display: 'inline',
                              alignItems: 'center',
                              margin: '2px',
                              cursor: 'pointer',
                              position: 'relative',
                              width: '10%',
                              overflowY: "hidden" ,
                              overflowX: "hidden",
                            }}
                            onClick={() => toggleDoc2Visibility(itemKey + "_red")}
                            >
                            {doc2Visibility[itemKey + "_red"] ? 'Expand Section' : 'Collapse Section'}
                            <span
                              style={{
                                marginLeft: '8px',
                                display: 'inline-block',
                                width: '0',
                                height: '0',
                                borderTop: '4px solid transparent',
                                borderBottom: '4px solid transparent',
                                borderLeft: '8px solid #526666',
                                transform: doc2Visibility[itemKey + "_red"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                transformOrigin: '4px 50%',
                                transition: 'transform 0.2s ease',
                              }}
                            ></span>
                          </section>
                          </div>
                                <a
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    marginTop: '-60px',
                                    marginLeft: '-53px',
                                    top: '40px',
                                    left: '0%',
                                    zIndex: '9999',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: 'rgb(0, 179, 181)',
                                    pointerEvents: 'none',
                                    fontSize: '20px',
                                  }}
                                >
                                  <img src={Stop}
                                  style={{
                                    maxWidth: '70px', // adjust as needed
                                    maxHeight: '70px', // adjust as needed
                                    objectFit: 'contain'
                                  }} />
                                </a>

                                {Object.entries(zones)
                                  .slice(3, 4)
                                  .map(([lineItems, lineItemsObject]) => (
                                    <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden', maxHeight: showDoc2 ? '100px' : 'unset', overflowY: showDoc2 ? 'hidden' : 'hidden'}} key={lineItems}>
                                      {Object.entries(lineItemsObject).map(([key1, val1]) => (
                                        <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} 
                                        style={noMatchTableStyle}>
                                        <thead>

                                          <th style={theadStyle}>
                                            {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed" : "Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          
                                          <div
                                            style={{
                                              fontStyle: 'italic',
                                              fontSize: '13px',
                                              color: '#526666',
                                              padding: '2px 0px',
                                              display: 'inline',
                                              margin: '2px',
                                              float: 'right',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => toggleDoc2Visibility(key1)}
                                          >
                                            Expand
                                            <span
                                              style={{
                                                marginLeft: '8px',
                                                display: 'inline-block',
                                                width: '0',
                                                height: '0',
                                                borderTop: '4px solid transparent',
                                                borderBottom: '4px solid transparent',
                                                borderLeft: '8px solid #526666',
                                                transform: doc2Visibility[key1] ? 'rotate(90deg)' : 'rotate(0deg)',
                                                transformOrigin: '4px 50%',
                                                transition: 'transform 0.2s ease',
                                              }}
                                            ></span>
                                          </div>
                                          </th>

                                        </thead>
                                            <tbody style={tbodyStyle} key={key1}>
                                            {Object.entries(val1).slice(0, 1).map(([key2, val2]) => {
                                              
                                              const totalEntry = Object.entries(val1).find(([key]) => key.toLowerCase() === 'total');
                                              
                                              return (
                                                <tr style={trStyle} key={key2}>
                                                  <th style={{...thStyle, display: "flex", justifyContent: "space-between", alignItems: "center", width:'90%' }}>
                                                  <span title={key2 + ": " + val2}>
                                                    {doc2Visibility[key1]
                                                      ? `${key2}: ${val2}`  
                                                      : `${key2.length > 20 ? key2.substring(0, 20) + "..." : key2}` + 
                                                        `: ${val2.length > 30 ? val2.substring(0, 30) + "..." : val2}`
                                                    }
                                                  </span>

                                                    {!doc2Visibility[key1] && totalEntry && (
                                                      <span style={{ fontWeight: "bold", textAlign: "right"}}>
                                                        <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                        <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                      </span>
                                                    )}
                                                  </th>
                                                </tr>
                                              );
                                            })}
                                            {doc2Visibility[key1] &&(
                                              <tr key={key1} style={trStyle}>
                                                <th style={verticalLabel}>
                                                  {findDocNumber(key1)}
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className={findDocNumber(key1) ? "doc1" : "doc2"}
                                                    onClick={async () => {
                                                      const isDoc1 = findDocNumber(key1);
                                                      const fieldName = isDoc1 ? "doc1_name" : "doc2_name";
                                                      const currentName = isDoc1 ? data?.doc1_name : data?.doc2_name;
                                                      const newName = window.prompt(`Enter a new name for ${fieldName}:`, currentName || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ [fieldName]: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                          alert(`${fieldName} updated successfully!`);
                                                          if (isDoc1) {
                                                            setDoc1Name(newName);
                                                          } else {
                                                            setDoc2Name(newName);
                                                          }
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                    >
                                                    {findDocNumber(key1) ? doc1Name : doc2Name || "Unnamed"}
                                                  </span>
                                                </th>
                                                {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                                  return (
                                                    <td style={containsPropVal}>
                                                      <td style={tdPropStyle} key={key2}>{key2}</td>
                                                      <td style={tdValueStyle} key={val2}>{val2}</td>
                                                    </td>
                                                  )
                                                })}
                                              </tr>
                                              )}
                                            </tbody>
                                            <tbody>
                                              {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                            </tbody>
                                            <tbody style={tbodyStyle}>
                                              <tr style={lastRow}>

                                                {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                <div>
                                                  <label
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      fontStyle: "italic",
                                                      color: "#526666",
                                                      margin: "2px",
                                                    }}
                                                  >
                                                    <span
                                                      onClick={() => handleCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                      style={{
                                                        display: "inline-block",
                                                        width: "16px",
                                                        height: "16px",
                                                        marginRight: "8px",
                                                        border: "2px solid #526666",
                                                        borderRadius: "4px",
                                                        backgroundColor: handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                          ? "#526666" // Checked state color
                                                          : "transparent",
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        padding: "0 2px",
                                                      }}
                                                    >
                                                      {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) && (
                                                        <span
                                                          style={{
                                                            position: "absolute",
                                                            top: "-8px",
                                                            left: "0px",
                                                            fontSize: "24px",
                                                            color: "white", // Color for the checkmark
                                                            padding: "0 0 0 2px",
                                                            margin: "0",
                                                          }}
                                                        >
                                                          ✔
                                                        </span>
                                                      )}
                                                    </span>
                                                    {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                      ? "Confirmed No-Match"
                                                      : "Potential No-Match"}
                                                  </label>

                                                  <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                                                    <span
                                                      onClick={() => handleCheckboxChange("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                      style={{
                                                        display: 'inline-block',
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '8px', // Spacing the checkbox before the label
                                                        border: '2px solid #526666',
                                                        borderRadius: '4px',
                                                        backgroundColor: markForExport.has("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)
                                                          ? '#526666' // Checked state color
                                                          : 'transparent',
                                                        cursor: 'pointer',
                                                        position: 'relative',
                                                        padding: '0 2px'
                                                      }}
                                                    >
                                                      {markForExport.has("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) && (
                                                        <span
                                                          style={{
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            left: '0px',
                                                            fontSize: '24px',
                                                            color: 'white', // Color for the checkmark
                                                            padding: '0 0 0 2px',
                                                            margin: '0'
                                                          }}
                                                        >
                                                          ✔
                                                        </span>
                                                      )}
                                                    </span>
                                                    Mark For Export
                                                  </label>
                                                </div>
                                              </tr>
                                            </tbody>
                                        </table>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                          )}
{/* ------------------------------------------------------------------------------------------------------------- */}
                          {Object.entries(zones).slice(2, 3).map(([lineItems, lineItemsObject]) => {
                            const greenTables = [];
                            const yellowTables = [];
                            const zeroTables = [];
                            
                            <div ref={pdfRef} key={lineItems} id={lineItems}>
                              


                              {Object.entries(lineItemsObject)
                                .filter(object => object[1]["Percentage"] >= percentValue)
                                .sort(function (a, b) { return (a[1]["Percentage"] - b[1]["Percentage"]) })
                                .map(([lineItemZero, lineItemZeroObject], index) => {
                                  const percentage = Number(lineItemZeroObject["Percentage"]);
                                  const itemKey = zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero;
                                  const showDoc2 = doc2Visibility[itemKey] || false;
                                  const entries = Object.entries(lineItemZeroObject);
                                  const totalEntry = entries.find(([key]) => key.toLowerCase() === 'total');
                                  
                                  const tableContent = (
                                    <table style={dynamicPercentColorChange(percentage, totalEntry)}
                                      id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                      key={lineItemZero}>

                                      <thead>
                                        <th style={theadStyle}>

                                          {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})
                                          <div
                                            style={{
                                              fontStyle: 'italic',
                                              fontSize: '13px',
                                              color: '#526666',
                                              padding: '2px 0px',
                                              display: 'inline',
                                              margin: '2px',
                                              float: 'right',
                                              cursor: 'pointer',
                                              zIndex: '999'
                                            }}
                                            onClick={() => toggleDoc2Visibility(itemKey)}
                                          >
                                            Expand
                                            <span
                                              style={{
                                                marginLeft: '8px',
                                                display: 'inline-block',
                                                width: '0',
                                                height: '0',
                                                zIndex: '999',
                                                borderTop: '4px solid transparent',
                                                borderBottom: '4px solid transparent',
                                                borderLeft: '8px solid #526666',
                                                transform: showDoc2 ? 'rotate(90deg)' : 'rotate(0deg)',
                                                transformOrigin: '4px 50%',
                                                transition: 'transform 0.2s ease',
                                              }}
                                            ></span>
                                          </div>
                                        </th>
                                      </thead>

                                      {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                        <tbody style={tbodyStyle}
                                          key={lineItem1}>
                                         {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) => {
                                         
                                         const totalEntry = Object.entries(lineItem1PropsVals).find(([key]) => key.toLowerCase() === 'total');

                                          return (
                                            <tr style={trStyle} key={desc}>
                                            <th 
                                              style={{ 
                                              ...thStyle,
                                              display: "flex", 
                                              justifyContent: "space-between", 
                                              alignItems: "center", 
                                              width: "90%" 
                                            }}>
                                              <span title={`${desc}: ${descVal}`}>
                                                {showDoc2 
                                                  ? `${desc}: ${descVal}`
                                                  : `${desc.length > maxLength ? desc.slice(0, maxLength) + "..." : desc}` + 
                                                    `: ${descVal.length > maxLength ? descVal.slice(0, maxLength) + "..." : descVal}`
                                                }
                                              </span>
                                              {!showDoc2 && totalEntry && (
                                                <span style={{ 
                                                  display: "flex", 
                                                  alignItems: "center", 
                                                  justifyContent: "flex-end", 
                                                  fontWeight: "bold", 
                                                  minWidth: "120px"
                                                }}>
                                                  <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                  <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                </span>
                                              )}
                                              
                                            </th>
                                          </tr>
                                          
                                          );
                                        })}
                                          {showDoc2 &&(
                                                <>
                                                  <tr style={trStyle}>
                                                  <th style={verticalLabel}>
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className="doc1"
                                                    onClick={async () => {
                                                      const newName = window.prompt("Enter a new name:", data?.doc1_name || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ doc1_name: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                      
                                                          alert("Database updated successfully!");
                                                          const elements = document.getElementsByClassName('doc1');
                                                          setDoc1Name(newName)
                                                          
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    {doc1Name || "Unnamed"}
                                                  </span>
                                                  </th>                
                                                {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                                  <td style={containsPropVal}>
                                                    <td style={tdPropStyle} key={quantity}>{quantity}</td>
                                                    <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                                  </td>
                                                )}
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      )}

                                      {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                        <tbody style={tbodyStyle} key={lineItem2}>
                                         {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) => {
                                         
                                         const totalEntry = Object.entries(lineItem2PropsVals).find(([key]) => key.toLowerCase() === 'total');

                                          return (
                                            <tr style={trStyle} key={desc}>
                                            <th 
                                              style={{ 
                                              ...thStyle,
                                              display: "flex", 
                                              justifyContent: "space-between", 
                                              width: "90%" 
                                            }}>
                                          
                                              <span title={`${desc}: ${descVal}`}>
                                                {showDoc2 
                                                  ? `${desc}: ${descVal}` 
                                                  : `${desc.length > maxLength ? desc.slice(0, maxLength) + "..." : desc}` + 
                                                    `: ${descVal.length > maxLength ? descVal.slice(0, maxLength) + "..." : descVal}`
                                                }
                                              </span>
                                          
                                              {!showDoc2 && totalEntry && (
                                                <span style={{ 
                                                  display: "flex", 
                                                  justifyContent: "flex-end", 
                                                  fontWeight: "bold", 
                                                  minWidth: "120px"
                                                }}>
                                                  <span style={{ color: "#00B3B5" }}>Total:</span> 
                                                  <span style={tdValueStyle}>{totalEntry[1]}</span>
                                                </span>
                                              )}
                                              
                                            </th>
                                          </tr>
                                            
                                          );
                                        })}
                                          {showDoc2 &&(
                                              <>
                                                  <tr style={trStyle}>
                                                  <th style={verticalLabel}>
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#00B3B5",
                                                      textDecoration: "underline",
                                                      zIndex: "1000",
                                                    }}
                                                    className="doc2"
                                                    onClick={async () => {
                                                      const newName = window.prompt("Enter a new name:", data?.doc2_name || "Unnamed");
                                                      if (newName !== null && newName.trim() !== "") {
                                                        try {
                                                          const claimId = comparisonID; 
                                                          if (!claimId) {
                                                            console.error("ERROR: Claim ID is missing!");
                                                            alert("Error: Claim ID is missing.");
                                                            return;
                                                          }
                                                          const requestBody = JSON.stringify({ doc2_name: newName });
                                                          const response = await fetch(`${process.env.REACT_APP_DOCNAMES}/${claimId}`, {
                                                            method: "PUT",
                                                            headers: { "Content-Type": "application/json" },
                                                            body: requestBody,
                                                          });
                                                          const result = await response.json();
                                                          if (!response.ok) {
                                                            throw new Error(result.error || "Failed to update the database");
                                                          }
                                                      
                                                          alert("Database updated successfully!");
                                                          const elements = document.getElementsByClassName('doc2');
                                                          setDoc2Name(newName)
                                                          
                                                        } catch (error) {
                                                          console.error("ERROR:", error);
                                                          alert("Failed to update the database. Please try again.");
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    {doc2Name || "Unnamed"}
                                                  </span>

                                                  </th>                
                                                {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                                  <td style={containsPropVal}>
                                                    <td style={tdPropStyle} key={quantity}>{quantity}</td>
                                                    <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                                  </td>
                                                )}
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      )}

                                      <tbody style={tbodyStyle}>
                                        <tr style={trStyleFlexStart}>
                                          {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>
                                            validateRCV(val)
                                          )}
                                          {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>
                                            validateACV(val)
                                          )}
                                          {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>
                                            validateTotal(key, val)
                                          )}
                                        </tr>
                                      </tbody>

                                      <tbody>
                                        {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                      </tbody>

                                      <tbody style={tbodyStyle}>

                                        <tr style={lastRow}>

                                          {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                          <td>
                                            <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                                              <span
                                                onClick={() => handleCheckboxChange(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`)}
                                                style={{
                                                  display: 'inline-block',
                                                  width: '16px',
                                                  height: '16px',
                                                  marginRight: '8px', // Spacing the checkbox before the label
                                                  border: '2px solid #526666',
                                                  borderRadius: '4px',
                                                  backgroundColor: markForExport.has(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`)
                                                    ? '#526666' // Checked state color
                                                    : 'transparent',
                                                  cursor: 'pointer',
                                                  position: 'relative',
                                                  padding: '0 2px'
                                                }}
                                              >
                                                {markForExport.has(`${zones["Zone 1"]} vs. ${zones["Zone 2"]} ${lineItemZero}`) && (
                                                  <span
                                                    style={{
                                                      position: 'absolute',
                                                      top: '-8px',
                                                      left: '0px',
                                                      fontSize: '24px',
                                                      color: 'white', // Color for the checkmark
                                                      padding: '0 0 0 2px',
                                                      margin: '0'
                                                    }}
                                                  >
                                                    ✔
                                                  </span>
                                                )}
                                              </span>
                                              Mark For Export
                                            </label>
                                          </td>
                                          
                                        </tr>
                                      </tbody>
                                    </table>
                                  );                
                                  if (totalEntry && totalEntry[1] == 0) {
                                    // Only push this specific tableContent to zeroTables if its total is zero.
                                    zeroTables.push(tableContent);
                                  } else if (percentage >= 40) {
                                    greenTables.push(tableContent);
                                  } else {
                                    yellowTables.push(tableContent);
                                  }
                                })}
                            </div>
                            return (
                              <div ref={pdfRef} key={lineItems} id={lineItems}>

{/* ------------------------------------------------------------------------------------------------------------- */}

                                {yellowTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + '_yellow'] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + '_yellow'] ? 'hidden' : 'auto'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-35px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={Yield} 
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}}/></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '40px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Yellow ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '44px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + '_yellow')}
                                    >
                                    {doc2Visibility[itemKey + '_yellow'] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + '_yellow'] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {yellowTables}
                                  </div>
                                )}

{/* ------------------------------------------------------------------------------------------------------------- */}
                              
                                  {greenTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + "_green"] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + "_green"] ? 'hidden' : 'auto'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-20px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      marginTop: '-40px',
                                      top: '40px',
                                      left: '0%',
                                      zIndex: '9999',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={Close}
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}} /></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '19px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Green ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '20px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + "_green")}
                                    >
                                    {doc2Visibility[itemKey + "_green"] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + "_green"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {greenTables}
                                  </div>
                                )}
{/* ------------------------------------------------------------------------------------------------------------- */}
                                  {zeroTables.length > 0 && (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    borderBottom: '2px solid rgb(0, 179, 181)',
                                    margin: '40px 0px 0px 0px',
                                    backgroundColor: '#ffffff',
                                    maxHeight: doc2Visibility[itemKey + "_zero"] ? '100px' : 'unset',
                                    overflowY: doc2Visibility[itemKey + "_zero"] ? 'hidden' : 'hidden'

                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      position: 'absolute',
                                      marginTop: '-20px',
                                      marginLeft: '46%',
                                      boxSizing: 'border-box',
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                      zIndex: '900',
                                      width: '54%',
                                      float: 'right',

                                    }}>
                                    <a style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      position: 'relative',
                                      marginTop: '-40px',
                                      top: '40px',
                                      left: '0%',
                                      zIndex: '9999',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      color: 'rgb(0, 179, 181)',
                                      pointerEvents: 'none',
                                      fontSize: '20px'
                                    }} ><img src={GO}
                                              style={{
                                              maxWidth: '70px',  
                                              maxHeight: '100px',
                                              objectFit: 'contain'}} /></a>
                                  <label style={{display: 'flex', marginLeft: '25%', alignItems: 'center', bottom: '19px', position: 'relative', right: '24px', fontStyle: 'italic', color: '#526666' }}>
                                    <span
                                      onClick={() => handleSectionExport(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)}
                                      style={{
                                        display: 'inline-block',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px', // Spacing the checkbox before the label
                                        border: '2px solid #526666',
                                        borderRadius: '4px',
                                        backgroundColor: markAllCheckboxes.has(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`)
                                          ? '#526666' // Checked state color
                                          : 'transparent',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        padding: '0 2px'
                                      }}
                                      id={`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`}
                                    >
                                      {markAllCheckboxes.has(`Zero ${zones["Zone 1"]} vs. ${zones["Zone 2"]}`) && (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            fontSize: '24px',
                                            color: 'white', // Color for the checkmark
                                            padding: '0 0 0 2px',
                                            margin: '0'
                                          }}
                                        >
                                          ✔
                                        </span>
                                      )}
                                    </span>
                                    Mark Section For Export
                                  </label>

                                  <section
                                    style={{
                                      position: 'relative',
                                      bottom: '20px',
                                      fontStyle: 'italic',
                                      fontSize: '13px',
                                      color: '#526666',
                                      padding: '2px 0px',
                                      display: 'inline',
                                      margin: '2px',
                                      float: 'right',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => toggleDoc2Visibility(itemKey + "_zero")}
                                    >
                                    {doc2Visibility[itemKey + "_zero"] ?  'Expand Section' : 'Collapse Section'}
                                    <span
                                      style={{
                                        marginLeft: '8px',
                                        display: 'inline-block',
                                        width: '0',
                                        height: '0',
                                        borderTop: '4px solid transparent',
                                        borderBottom: '4px solid transparent',
                                        borderLeft: '8px solid #526666',
                                        transform: doc2Visibility[itemKey + "_zero"] ? 'rotate(0deg)' : 'rotate(90deg)',
                                        transformOrigin: '4px 50%',
                                        transition: 'transform 0.2s ease',
                                      }}
                                    ></span>
                                  </section>
                                  </div>
                                    {zeroTables}
                                  </div>
                                )}

{/* ------------------------------------------------------------------------------------------------------------- */}
                              </div>
                            );
                          })}
{/* ------------------------------------------------------------------------------------------------------------- */}
                          </div>
                            )
                          }
                        }
                      }
                    }
                    )}
                  </div>

                </div>

              )}

              <div style={borderTop}>
                {noMatchesEndOfResultsA('zzz')}
                {noMatchesEndOfResultsB('zzz')}
              </div>
            </div>
          </div>
        )
      }
    }

  }


  function bestMatch(zone1Name) {
    if (data["claimData"] != null) {
      let resultsLayer = data[currentSection]
      let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0, 1).toString())
      let noZone1Duplicates = [...new Set(resLayer1)]
      let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
      let noZone2Duplicates = [...new Set(resLayer2)]
      return (
        Object.entries(stringSimilarity.findBestMatch(zone1Name, noZone2Duplicates)).slice(1, 2).map(([key, val]) =>
          Object.entries(val).slice(0, 1).map(([key2, val2]) => {
            return (
              val2
            )
          }
          )))
    }
  }

  function toggleView() {
    setToggled(!toggled)
  }


  // Filter line items based on selected zones and filter text
  const filterLineItems = () => {
    if (!data.results) return;

    const filteredZone1Items = [];
    const filteredZone2Items = [];

    const zone1Set = new Set();
    const zone2Set = new Set();

    Object.entries(data.results).forEach(([key, item]) => {
      const zone1Name = item["Zone 1"];
      const zone2Name = item["Zone 2"];

      Object.entries(item["Line Items"]).forEach(([lineItemKey, lineItemValue]) => {
        const line1Key = `${zone1Name}-${lineItemValue["Line Item 1"]?.DESCRIPTION || ""}`;
        const line2Key = `${zone2Name}-${lineItemValue["Line Item 2"]?.DESCRIPTION || ""}`;

        // Function to check if filter text exists in line item properties
        const matchesFilterZone1 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone1.toLowerCase())
          );
        };

        const matchesFilterZone2 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone2.toLowerCase())
          );
        };

        // Add Line Item 1 to Zone 1 items if it matches the filter text for Document 1
        if (
          (selectedZone1.length === 0 || selectedZone1.includes(zone1Name)) &&
          lineItemValue["Line Item 1"] &&
          !zone1Set.has(line1Key) &&
          matchesFilterZone1(lineItemValue["Line Item 1"])
        ) {
          zone1Set.add(line1Key);
          filteredZone1Items.push({ zone: zone1Name, item: lineItemValue["Line Item 1"] });
        }

        // Add Line Item 2 to Zone 2 items if it matches the filter text for Document 2
        if (
          (selectedZone2.length === 0 || selectedZone2.includes(zone2Name)) &&
          lineItemValue["Line Item 2"] &&
          !zone2Set.has(line2Key) &&
          matchesFilterZone2(lineItemValue["Line Item 2"])
        ) {
          zone2Set.add(line2Key);
          filteredZone2Items.push({ zone: zone2Name, item: lineItemValue["Line Item 2"] });
        }
      });
    });

    setFilteredItems({ zone1: filteredZone1Items, zone2: filteredZone2Items });
  };

  const handleZone1Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone1(selectedValues);
  };

  const handleZone2Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone2(selectedValues);
  };

  const handleFilterTextZone1Change = (event) => {
    setFilterTextZone1(event.target.value);
  };

  const handleFilterTextZone2Change = (event) => {
    setFilterTextZone2(event.target.value);
  };

  const handleDoc1NameChange = (event) => {

  }
  // You can then merge this style into the sx prop if you're using it inline with your Button.

  const handleFocus = () => {

    if (newViewArray.length > 0) {
      if (!window.confirm('Changing the filters will clear your selections. Proceed?')) {
        return;
      }
    }

    setNewViewArray([]);
  };

  const renderView = () => {
    const zone1Set = new Set();
    const zone2Set = new Set();

    if (data.results) {
      Object.entries(data.results).forEach(([key, item]) => {
        if (item["Zone 1"]) zone1Set.add(item["Zone 1"]);
        if (item["Zone 2"]) zone2Set.add(item["Zone 2"]);
      });
    }

    const uniqueZone1s = Array.from(zone1Set).map(zone => ({ value: zone, label: zone }));
    const uniqueZone2s = Array.from(zone2Set).map(zone => ({ value: zone, label: zone }));
    const clearFilterText1 = () => {
      setFilterTextZone1('');
    };
    const clearFilterText2 = () => {
      setFilterTextZone2('');
    };

    const truncateValue = (value) => {
      return value.length > 60 ? value.slice(0, 60) + '...' : value;
    };

    return (
      <div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <div onClick={handleFocus}>
            <label>Doc 1 Zones: </label>
            <Select
              isMulti
              value={uniqueZone1s.filter(opt => selectedZone1.includes(opt.value))}
              onChange={handleZone1Change}
              options={uniqueZone1s}
              placeholder="Select Zone"
            />
            <br />
            <label>Filter (Doc 1): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone1}
              onChange={handleFilterTextZone1Change}
              placeholder="Enter text to filter"
            />
            {filterTextZone1 && (
              <button
                onClick={clearFilterText1}
                style={{
                  transform: 'translateX(-150%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                ×
              </button>
            )}

          </div>

          <div onClick={handleFocus}>
            <label>Doc 2 Zones: </label>
            <Select
              isMulti
              value={uniqueZone2s.filter(opt => selectedZone2.includes(opt.value))}
              onChange={handleZone2Change}
              options={uniqueZone2s}
              placeholder="Select Zone"
            />
            <br />
            <label>Filter (Doc 2): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone2}
              onChange={handleFilterTextZone2Change}
              placeholder="Enter text to filter"
            />
            {filterTextZone2 && (
              <button
                onClick={clearFilterText2}
                style={{
                  transform: 'translateX(-150%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                ×
              </button>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ flex: 1, marginRight: '10px', boxSizing: 'border-box' }}>
            {filteredItems.zone1.length > 0 ? (
              filteredItems.zone1.map((entry, index) => {
                // Create a unique ID using the entry's zone and the index
                const uniqueId = `Doc-1-${entry.zone}-${index}`;

                return (
                  <div id={uniqueId} key={uniqueId}>
                    <div style={filterTableStyle}>
                      {/* Display the first entry on top */}
                      {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                        <div key={key} style={blockStyle}>
                          <h4 style={centeredHeader}> (Doc 1) Zone: {entry.zone}</h4>
                          <hr />
                          <br />
                          <div style={thStyle}>{key}: {truncateValue(value)}</div>
                          <br />
                        </div>
                      ))}

                      {/* Display the rest of the entries below */}
                      <div style={trStyle}>
                        {Object.entries(entry.item).slice(1).map(([key, value]) => (
                          <div key={key}>
                            <div style={tdPropStyle}>{key}</div>
                            <div style={tdValueStyle}>{value}</div>
                            <br />
                          </div>
                        ))}
                      </div>

                      <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                        <span
                          onClick={() => handleCheckboxChange(uniqueId)}
                          style={{
                            display: 'inline-block',
                            width: '16px',
                            height: '16px',
                            marginRight: '8px', // Spacing the checkbox before the label
                            border: '2px solid #526666',
                            borderRadius: '4px',
                            backgroundColor: markForExport.has(uniqueId)
                              ? '#526666' // Checked state color
                              : 'transparent',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: '0 2px'
                          }}
                        >
                          {markForExport.has(uniqueId) && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '0px',
                                fontSize: '24px',
                                color: 'white', // Color for the checkmark
                                padding: '0 0 0 2px',
                                margin: '0'
                              }}
                            >
                              ✔
                            </span>
                          )}
                        </span>
                        Mark For Export
                      </label>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No line items for Doc 1.</p>
            )}
          </div>

          <div style={{ flex: 1, marginLeft: '10px', boxSizing: 'border-box' }}>
            {filteredItems.zone2.length > 0 ? (
              filteredItems.zone2.map((entry, index) => {
                // Create a unique ID using the entry's zone and the index
                const uniqueId = `Doc-2-${entry.zone}-${index}`;

                return (
                  <div id={uniqueId} key={uniqueId}>
                    <div style={filterTableStyle}>
                      {/* Display the first entry on top */}
                      {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                        <div key={key} style={blockStyle}>
                          <h4 style={centeredHeader}>(Doc 2) Zone: {entry.zone}</h4>
                          <hr />
                          <br />
                          <div style={thStyle}>{key}: {truncateValue(value)}</div>
                          <br />
                        </div>
                      ))}

                      {/* Display the rest of the entries below */}
                      <div style={trStyle}>
                        {Object.entries(entry.item).slice(1).map(([key, value]) => (
                          <div key={key}>
                            <div style={tdPropStyle}>{key}</div>
                            <div style={tdValueStyle}>{value}</div>
                            <br />
                          </div>
                        ))}
                      </div>
                                          
                      <label style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic', color: '#526666', margin: '2px' }}>
                        <span
                          onClick={() => handleCheckboxChange(uniqueId)}
                          style={{
                            display: 'inline-block',
                            width: '16px',
                            height: '16px',
                            marginRight: '8px', // Spacing the checkbox before the label
                            border: '2px solid #526666',
                            borderRadius: '4px',
                            backgroundColor: markForExport.has(uniqueId)
                              ? '#526666' // Checked state color
                              : 'transparent',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: '0 2px'
                          }}
                        >
                          {markForExport.has(uniqueId) && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '0px',
                                fontSize: '24px',
                                color: 'white', // Color for the checkmark
                                padding: '0 0 0 2px',
                                margin: '0'
                              }}
                            >
                              ✔
                            </span>
                          )}
                        </span>
                        Mark For Export
                      </label>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No line items for Doc 2.</p>
            )}
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className={'outer-container'}>
      <MainWrapper />
      <div className={'right-container'}>
        <div className={'comparison-results-container'}>
          <h1 className={'h1-comparison-results'}>
            {toggled ? 'Comparison Results - Zone by Zone' : 'Comparison Results - Filter by Descriptions'}
          </h1>

          <div className={'comparison-results-actions-div'}>
            <button onClick={handleClick} className="hamburger-button">&#x22EE;</button>
            {menuOpen && (
              <ul className="dropdown-menu">
                <li onClick={() => { toggleView(); handleClose(); }}>Toggle View</li>
                {toggled && <li onClick={() => { saveChanges(comparisonID); handleClose(); }}>Save Changes</li>}
                {toggled && (
                  <li>
                    <label className="filter-label" htmlFor="filter-percentage">
                      LEVLR Match Assistance
                    </label>
                    <input
                      id="filter-percentage"
                      type="range"
                      min="0"
                      max={MAX}
                      onChange={(e) => setPercentValue(e.target.value)}
                      style={getBackgroundSize()}
                      value={percentValue}
                    />
                  </li>
                )}
                {/* Add the export buttons as list items */}
                {!toggled && (
                  <li style={{animation: newViewArray.length > 0 ? 'fluctuateColor 2s infinite alternate' : 'none'}} onClick={confirmExportSelection}>
                    
                      Add Selections To PDF
                  </li>
                )}
                <li onClick={openPreviewInNewTab}>
                    Preview PDF
                </li>
                <li  
                    onClick={() => {
                      if (newViewArray.length > 0) {
                        alert("You have selections that are not added to the PDF!");
                      } else {
                        generatePDF(); 
                      }
                    }}>
                    Export PDF
                </li>
              </ul>
            )}
          </div>


          <div id={'pairings'} className={'pairings-container'}>
            <div className={loadingStatus}></div>
            {toggled ? (
              <>
                {renderZones()}
                {renderNote()}
              </>
            ) : (
              renderView()
            )}
          </div>
        </div>
      </div>

      <div style={pdfCopy} id={'pdfCopy'}>
        {renderClaimCredentials()}
      </div>
    </div>
  );
};
export default ComparisonResults